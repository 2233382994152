import * as THREE from "three";
import Experience, {
    SCENE_HOME,
    SCENE_WHO,
    SCENE_FAQ,
    SCENE_WHAT,
} from "./Experience.js";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/addons/postprocessing/OutlinePass.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

export default class Renderer {
    constructor() {
        this.experience = new Experience();
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.scene2 = this.experience.scene2;
        this.camera = this.experience.camera;
        this.camera2 = this.experience.camera2;
        this.debug = this.experience.debug;

        this.setInstance();
        this.setPostProcessing();
        this.setPostProcessingWhat();

        // this.setOutlineDebug();
    }

    setInstance() {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
        });
        this.instance.physicallyCorrectLights = true;
        this.instance.shadowMap.enabled = true;
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap;

        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    setPostProcessing() {
        const renderScene = new RenderPass(this.scene, this.camera.instance);

        const bloomPass = new UnrealBloomPass(
            new THREE.Vector2(this.sizes.width, this.sizes.height),
            1.5,
            0.4,
            0.85
        );
        bloomPass.threshold = 0.15;
        bloomPass.strength = 1.5;
        bloomPass.radius = 0.6;

        this.bloomComposer = new EffectComposer(this.instance);
        this.bloomComposer.renderToScreen = false;
        this.bloomComposer.addPass(renderScene);
        this.bloomComposer.addPass(bloomPass);

        const finalPass = new ShaderPass(
            new THREE.ShaderMaterial({
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: {
                        value: this.bloomComposer.renderTarget2.texture,
                    },
                },
                vertexShader: `varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
                fragmentShader: `uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;
                varying vec2 vUv;
                void main() {
                    gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
                }`,
                defines: {},
            }),
            "baseTexture"
        );
        finalPass.needsSwap = true;

        this.finalComposer = new EffectComposer(this.instance);
        this.finalComposer.addPass(renderScene);
        this.finalComposer.addPass(finalPass);

        // SMAA Pass
        if (
            this.instance.getPixelRatio() == 1 &&
            !this.instance.capabilities.isWebGL2
        ) {
            const smaaPass = new SMAAPass();
            this.finalComposer.addPass(smaaPass);
        }
    }

    addOutlineObject(object) {
        this.outlinePass.selectedObjects.push(object);
    }

    clearOutlineObjects() {
        this.outlinePass.selectedObjects = [];
    }

    setPostProcessingWhat() {
        this.composer2 = new EffectComposer(this.instance);

        // Scene Render
        const renderScene2 = new RenderPass(this.scene2, this.camera2.instance);
        this.composer2.addPass(renderScene2);

        // Outline Render
        this.outlinePass = new OutlinePass(
            new THREE.Vector2(this.sizes.width, this.sizes.height),
            this.scene2,
            this.camera2.instance
        );

        this.outlinePass.edgeStrength = 8;
        this.outlinePass.edgeGlow = 1;
        this.outlinePass.pulsePeriod = 4;
        this.outlinePass.hiddenEdgeColor.set("#ffffff");
    }

    addOutlinePass(add) {
        if (add) {
            this.composer2.addPass(this.outlinePass);
        } else {
            this.composer2.removePass(this.outlinePass);
        }
    }

    setOutlineDebug() {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("outline");

            this.debugFolder
                .add(this.outlinePass, "edgeStrength")
                .name("edgeStrength")
                .min(0)
                .max(10)
                .step(0.001);

            this.debugFolder
                .add(this.outlinePass, "edgeGlow")
                .name("edgeGlow")
                .min(0)
                .max(5)
                .step(0.001);

            this.debugFolder
                .add(this.outlinePass, "edgeThickness")
                .name("edgeThickness")
                .min(0)
                .max(4)
                .step(0.001);

            this.debugFolder
                .add(this.outlinePass, "pulsePeriod")
                .name("pulsePeriod")
                .min(0)
                .max(5)
                .step(0.001);

            this.debugFolder.addColor(this.outlinePass, "visibleEdgeColor");
            this.debugFolder.addColor(this.outlinePass, "hiddenEdgeColor");
        }
    }

    resize() {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    update() {
        this.instance.autoClear = false;
        this.instance.clear();

        if (
            this.experience.activeScene == SCENE_HOME ||
            this.experience.activeScene == SCENE_WHO ||
            this.experience.activeScene == SCENE_FAQ
        ) {
            // Draw background (no bloom)
            this.camera.instance.layers.set(this.experience.world.HOME_BG);
            if (this.experience.world.sky) {
                this.experience.world.sky.darkenMaterial();
                this.bloomComposer.render();
                this.experience.world.sky.restoreMaterial();
            }
            this.finalComposer.render();

            // Draw layer without bloom.
            this.instance.clearDepth();
            this.camera.instance.layers.set(this.experience.world.HOME_LAYER);
            this.instance.render(this.scene, this.camera.instance);

            // Draw UI layer.
            this.instance.clearDepth();
            this.camera.instance.layers.set(this.experience.world.UI_LAYER);
            this.instance.render(this.scene, this.camera.instance);

            // Draw overlay.
            this.camera.instance.layers.set(
                this.experience.world.OVERLAY_LAYER
            );
            this.instance.render(this.scene, this.camera.instance);
        } else if (this.experience.activeScene == SCENE_WHAT) {
            // Render scene
            this.camera2.instance.layers.set(0);
            this.instance.clearDepth();
            this.composer2.render();

            // Draw overlay.
            this.savedBackground = this.experience.scene2.background;
            this.experience.scene2.background = null;
            this.camera2.instance.layers.set(
                this.experience.product.OVERLAY_LAYER
            );
            this.instance.render(this.scene2, this.camera2.instance);
            this.experience.scene2.background = this.savedBackground;
        }

        this.camera.instance.layers.enableAll();
    }
}
