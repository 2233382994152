import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 25.6;

export default class Penguin extends CompareObject {
    constructor() {
        super();

        this.model = this.resources.items.Penguin.scene.clone();
        super.setModel();
        this.setPosition();
    }

    setPosition() {
        this.model.position.set(17, 0, 0);
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }
}
