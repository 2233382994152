import * as THREE from "three";
import Experience from "../Experience.js";

export default class Floor {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        this.setGeometry();
        this.setTextures();
        this.setMaterial();
        this.setMesh();
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(100, 110);
    }

    setTextures() {
        this.textures = {};

        this.textures.color = this.resources.items.sandColorTexture;
        this.textures.color.repeat.set(8, 8);
        this.textures.color.wrapS = THREE.RepeatWrapping;
        this.textures.color.wrapT = THREE.RepeatWrapping;

        this.textures.normal = this.resources.items.sandNormalTexture;
        this.textures.normal.repeat.set(8, 8);
        this.textures.normal.wrapS = THREE.RepeatWrapping;
        this.textures.normal.wrapT = THREE.RepeatWrapping;

        this.textures.ao = this.resources.items.sandAOTexture;
        this.textures.ao.repeat.set(8, 8);
        this.textures.ao.wrapS = THREE.RepeatWrapping;
        this.textures.ao.wrapT = THREE.RepeatWrapping;
    }

    setMaterial() {
        this.material = new THREE.MeshStandardMaterial({
            map: this.textures.color,
            normalMap: this.textures.normal,
            aoMap: this.textures.ao, // require uv
        });
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.geometry.setAttribute(
            "uv2",
            new THREE.Float32BufferAttribute(
                this.mesh.geometry.attributes.uv.array,
                2
            )
        );

        this.mesh.rotation.x = -Math.PI * 0.5;
        this.mesh.receiveShadow = true;
        this.mesh.position.z = 51;

        this.mesh.layers.set(this.experience.world.HOME_LAYER);

        this.scene.add(this.mesh);
    }
}
