import * as THREE from "three";
import Experience from "../Experience.js";
import { SpriteAnimator } from "../Utils/SpriteAnimator.js";
import GetColorBetweenColors from "../Utils/Color.js";

const xPos = [2.4, -0.21, -3];
const yPos = [3.1, 6.51, 0.7];
const zPos = [5.01, 5.01, 5.5];
const animationFrames = [24, 24, 24];
const animationDurations = [3200, 3200, 3200];
const spriteScale = 2;
const spriteScaleY = [spriteScale * 1.25, spriteScale, spriteScale];

const shadowScale = 14;
const shadowScaleY = [shadowScale * 1.25, shadowScale, shadowScale];

// Shared
let spriteResources = [];
let modelResources = [];
let invisMaterial = new THREE.MeshBasicMaterial({
    color: 0x000000,
    transparent: true,
    opacity: 0.001,
});

export function getTeamCameraPosition(index) {
    const pos = new THREE.Vector3();
    pos.set(xPos[index], yPos[index] + 2.5, zPos[index] + 13);
    return pos;
}

export function getFAQPosition() {
    const pos = new THREE.Vector3();
    pos.set(xPos[1], yPos[1], zPos[1] + 30);
    return pos;
}

export default class WildWesterDev {
    constructor(devID) {
        this.devID = devID;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                this.resources.items.dev1,
                this.resources.items.dev2,
                this.resources.items.dev3,
            ];
        }
        if (modelResources.length == 0) {
            modelResources = [
                this.resources.items.dev1Shadow,
                this.resources.items.dev2Shadow,
                this.resources.items.dev3Shadow,
            ];
        }

        this.modelResource = modelResources[this.devID].scene.clone();

        this.tilesHoriz = animationFrames[this.devID];
        this.tilesVert = 1;

        this.group = new THREE.Group();
        this.setSprite();
        this.setShadow();
    }

    setShadow() {
        this.shadowModel = this.modelResource;
        this.shadowModel.rotation.x = Math.PI * 0.5;
        this.shadowModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = invisMaterial;
            }
        });

        this.shadowModel.scale.set(
            shadowScale,
            shadowScaleY[this.devID],
            shadowScale
        );
        if (this.devID == 0) {
            this.shadowModel.position.y = 1.25;
        } else {
            this.shadowModel.position.y = 1;
        }
        this.shadowModel.position.x = -1;

        this.shadowModel.children.forEach((obj) => {
            obj.layers.set(this.experience.world.HOME_LAYER);
        });
        this.updateShadow(this.spriteAnimator.currentTile);

        this.group.add(this.shadowModel);
    }

    // Updates shadow to the current frame.
    updateShadow(currentFrame) {
        // Handle Jack shadow.
        if (this.devID == 0) {
            if (currentFrame >= 0 && currentFrame <= 11) {
                this.shadowModel.children[0].castShadow = true;
                this.shadowModel.children[1].castShadow = false;
            } else {
                this.shadowModel.children[0].castShadow = false;
                this.shadowModel.children[1].castShadow = true;
            }
        } else if (this.devID == 1) {
            if (currentFrame >= 0 && currentFrame <= 11) {
                this.shadowModel.children[0].castShadow = true;
                this.shadowModel.children[1].castShadow = false;
                this.shadowModel.children[2].castShadow = false;
            } else if (currentFrame == 12) {
                this.shadowModel.children[1].castShadow = true;
                this.shadowModel.children[0].castShadow = false;
                this.shadowModel.children[2].castShadow = false;
            } else {
                this.shadowModel.children[2].castShadow = true;
                this.shadowModel.children[0].castShadow = false;
                this.shadowModel.children[1].castShadow = false;
            }
        } else if (this.devID == 2) {
            if (currentFrame >= 0 && currentFrame <= 11) {
                this.shadowModel.children[0].castShadow = true;
                this.shadowModel.children[1].castShadow = false;
                this.shadowModel.children[2].castShadow = false;
            } else if (currentFrame == 12) {
                this.shadowModel.children[1].castShadow = true;
                this.shadowModel.children[0].castShadow = false;
                this.shadowModel.children[2].castShadow = false;
            } else {
                this.shadowModel.children[2].castShadow = true;
                this.shadowModel.children[0].castShadow = false;
                this.shadowModel.children[1].castShadow = false;
            }
        }
    }

    setSprite() {
        // Resource
        this.resources = spriteResources[this.devID].clone();

        // Animated Sprite
        this.spriteAnimator = new SpriteAnimator(
            this.resources,
            this.tilesHoriz,
            this.tilesVert,
            this
        );
        this.spriteAnimator.sprite.layers.set(this.experience.world.HOME_LAYER);
        this.group.add(this.spriteAnimator.sprite);

        this.group.position.set(
            xPos[this.devID],
            yPos[this.devID],
            zPos[this.devID]
        );
        this.spriteAnimator.sprite.scale.set(
            spriteScale,
            spriteScaleY[this.devID],
            spriteScale
        );

        const framesToLoop = this.getArrayFromRange(
            0,
            animationFrames[this.devID] - 1
        );
        this.spriteAnimator.loop(framesToLoop, animationDurations[this.devID]);

        this.scene.add(this.group);
    }

    getArrayFromRange(start, end) {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.spriteAnimator.sprite.material.color.set(newColor);

        this.spriteAnimator.update();
    }
}
