import * as THREE from "three";

import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import Resources from "./Utils/Resources.js";
import sources from "./sources.js";
import ScrollDetector from "./Utils/ScrollDetector.js";
import ProductDemo from "./ProductDemo/ProductDemo.js";
import CameraTwo from "./CameraTwo.js";
import MainMenu from "./World/MainMenu.js";
import {
    getFAQPosition,
    getTeamCameraPosition,
} from "./World/WildWesterDev.js";
import ProductPageController from "./ProductDemo/ProductPageController.js";
import TWEEN from "@tweenjs/tween.js";
import DevCommentary from "./DevCommentary.js";

let instance = null;

export const SCENE_HOME = 1;
export const SCENE_WHAT = 2;
export const SCENE_WHO = 3;
export const SCENE_FAQ = 4;

const TEAM_TWEEN_DURATION = 600;

export default class Experience {
    constructor(_canvas, what) {
        // Skip to what scene.
        this.what = what;

        // Singleton
        if (instance) {
            return instance;
        }
        instance = this;

        // Detect mobile
        this.isMobile = this.checkIsMobile();

        // Global access
        window.experience = this;

        // Options
        this.canvas = _canvas;

        // Setup
        this.debug = new Debug();
        this.sizes = new Sizes();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.scene2 = new THREE.Scene();

        // Loaders
        this.loadingBarElement = document.querySelector(".loading-bar");
        this.loadingSpriteElement = document.querySelector(".loading-sprite");
        this.resources = new Resources(sources);

        this.scroll = new ScrollDetector();
        this.scroll.lockScroll(true);

        // Dev Commentary
        this.devCommentary = new DevCommentary();

        this.camera = new Camera();
        this.camera2 = new CameraTwo();
        this.renderer = new Renderer();

        // Scenes
        this.activeScene = SCENE_HOME;
        this.inTransition = false;

        this.world = new World();
        this.product = new ProductDemo();

        // Resize event
        this.sizes.on("resize", () => {
            this.resize();
        });

        // Time tick event
        this.time.on("tick", () => {
            this.update();
        });

        // Menu
        this.mainMenu = new MainMenu();
        this.productPageController = new ProductPageController();

        // Who Scene
        this.whoIndex = 1;

        this.loadingCompleted = false;
    }

    checkIsMobile() {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    playCommentary(quoteArray) {
        this.devCommentary.playQuote(quoteArray);
    }

    changeProductPreview(index) {
        this.product.product.updateImageController(index);
    }

    loadingProgress(progress) {
        this.loadingBarElement.style.transform = `scaleX(${progress})`;

        const spriteProgress = Math.floor((1 - progress) * 100);
        this.loadingSpriteElement.style.left = `${spriteProgress}%`;
    }

    loadingFinished() {
        // Wait a little
        window.setTimeout(() => {
            this.time.pauseDelta = false;
            this.world.overlay.removeOverlay(3, 1);

            // Update loadingBarElement
            this.loadingBarElement.classList.add("ended");
            this.loadingBarElement.style.transform = "";

            // Update loadingSpriteElement
            this.loadingSpriteElement.classList.add("ended");
        }, 500);

        window.setTimeout(() => {
            this.loadingCompleted = true;
        }, 500);

        window.setTimeout(() => {
            this.scroll.lockScroll(false);

            this.devCommentary.showButton(true);
        }, 2000);
    }

    nextTeamMember() {
        this.whoIndex = (this.whoIndex + 1) % 3;
        this.camera.tweenCamera(
            getTeamCameraPosition(this.whoIndex),
            TEAM_TWEEN_DURATION
        );
        this.mainMenu.showDevBio(true, this.whoIndex);
    }

    previousTeamMember() {
        this.whoIndex = (this.whoIndex + 3 - 1) % 3;
        this.camera.tweenCamera(
            getTeamCameraPosition(this.whoIndex),
            TEAM_TWEEN_DURATION
        );
        this.mainMenu.showDevBio(true, this.whoIndex);
    }

    goBack() {
        if (this.transitionTimeout) {
            clearTimeout(this.transitionTimeout);
        }
        this.changeScene(SCENE_HOME);
    }

    resize() {
        this.camera.resize();
        this.camera2.resize();
        this.renderer.resize();
    }

    update() {
        if (this.loadingCompleted) {
            this.scroll.update();
            TWEEN.update();

            if (
                this.activeScene == SCENE_HOME ||
                this.activeScene == SCENE_WHO ||
                this.activeScene == SCENE_FAQ
            ) {
                this.camera.update();
                this.world.update();
            } else if (this.activeScene == SCENE_WHAT) {
                this.camera2.update();
                this.product.update();
            }

            // Main Menu
            if (this.mainMenu) {
                this.mainMenu.update();
            }

            // Dev Commentary
            this.devCommentary.update();
        }
        this.renderer.update();
    }

    changeScene(sceneID) {
        if (this.activeScene == sceneID) {
            return;
        }

        // Change to home.
        if (sceneID == SCENE_HOME) {
            this.world.reduceOpacity(false);
            this.mainMenu.showBack(false);
        } else {
            this.mainMenu.showBack(true);
            this.mainMenu.showMenu(false);
        }

        // Change to what.
        if (sceneID == SCENE_WHAT) {
            //Transition
            if (this.what) {
                this.scroll.scrollProgress = 1;
                this.scroll.trueProgress = 1;
                this.product.overlay.removeOverlay(3, 0.5);
                this.productPageController.showPage(0, true);
                this.activeScene = sceneID;
            } else {
                this.world.overlay.addOverlay(0.5, 0);
                this.inTransition = true;

                //then change scene to what after delay
                let timeoutID = window.setTimeout(() => {
                    this.product.overlay.removeOverlay(3, 0);
                    this.productPageController.showPage(0, true);
                    this.activeScene = sceneID;
                    this.inTransition = false;
                }, 500);
                this.transitionTimeout = timeoutID;
            }
        } else {
            this.productPageController.showPage(-1, true);
            this.world.overlay.clearOverlay();
        }

        // Change to who.
        if (sceneID == SCENE_WHO) {
            // Change opacity of wildwesters and foliage.
            this.world.reduceOpacity(true);

            this.whoIndex = 0;
            this.nextTeamMember();
        } else {
            this.mainMenu.showDevButtons(false);
        }

        // Change to faq.
        if (sceneID == SCENE_FAQ) {
            this.mainMenu.showFAQ(true);
            // Move camera.
            this.camera.tweenCamera(getFAQPosition(), TEAM_TWEEN_DURATION);
        } else {
            this.mainMenu.showFAQ(false);
        }

        // Changed in timeout function for what scene.
        if (sceneID != SCENE_WHAT) {
            this.activeScene = sceneID;
        }
    }

    disposeNode(parentObject) {
        parentObject.traverse(function (node) {
            if (node instanceof THREE.Mesh) {
                if (node.geometry) {
                    node.geometry.dispose();
                }

                if (node.material) {
                    if (
                        node.material instanceof THREE.MeshFaceMaterial ||
                        node.material instanceof THREE.MultiMaterial
                    ) {
                        node.material.materials.forEach(function (mtrl, idx) {
                            if (mtrl.map) mtrl.map.dispose();
                            if (mtrl.lightMap) mtrl.lightMap.dispose();
                            if (mtrl.bumpMap) mtrl.bumpMap.dispose();
                            if (mtrl.normalMap) mtrl.normalMap.dispose();
                            if (mtrl.specularMap) mtrl.specularMap.dispose();
                            if (mtrl.envMap) mtrl.envMap.dispose();

                            mtrl.dispose(); // disposes any programs associated with the material
                        });
                    } else {
                        if (node.material.map) node.material.map.dispose();
                        if (node.material.lightMap)
                            node.material.lightMap.dispose();
                        if (node.material.bumpMap)
                            node.material.bumpMap.dispose();
                        if (node.material.normalMap)
                            node.material.normalMap.dispose();
                        if (node.material.specularMap)
                            node.material.specularMap.dispose();
                        if (node.material.envMap)
                            node.material.envMap.dispose();

                        node.material.dispose(); // disposes any programs associated with the material
                    }
                }
            }
        });
    }

    destroy() {
        this.sizes.off("resize");
        this.time.off("tick");
        this.resources.off("ready");

        disposeNode(this.scene);
        disposeNode(this.scene2);

        this.camera2.controls.dispose();
        this.renderer.instance.dispose();
    }
}
