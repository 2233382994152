import * as THREE from "three";
import { gsap } from "gsap";

export default class Overlay {
    constructor(scene, layer) {
        this.scene = scene;
        this.layer = layer;

        this.setOverlay();
    }

    isOverlaying() {
        return (
            this.overlayMaterial.uniforms.uAlpha.value != 1 ||
            this.overlayMaterial.uniforms.uAlpha.value != 0
        );
    }

    clearGSAP() {
        gsap.killTweensOf(this.overlayMaterial.uniforms.uAlpha);
    }

    clearOverlay() {
        this.clearGSAP();
        this.overlayMaterial.uniforms.uAlpha.value = 0;
    }

    addOverlay(duration, delay) {
        this.clearGSAP();
        this.overlayMaterial.uniforms.uAlpha.value = 0;
        gsap.to(this.overlayMaterial.uniforms.uAlpha, {
            duration: duration,
            value: 1,
            delay: delay,
        });
    }

    removeOverlay(duration, delay) {
        this.clearGSAP();
        this.overlayMaterial.uniforms.uAlpha.value = 1;
        gsap.to(this.overlayMaterial.uniforms.uAlpha, {
            duration: duration,
            value: 0,
            delay: delay,
        });
    }

    setOverlay() {
        this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
        this.overlayMaterial = new THREE.ShaderMaterial({
            transparent: true,
            uniforms: {
                uAlpha: { value: 1 },
            },
            vertexShader: `
                void main() {
                    gl_Position = vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uAlpha;
                void main() {
                    gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
                }
            `,
        });
        this.overlay = new THREE.Mesh(
            this.overlayGeometry,
            this.overlayMaterial
        );

        this.overlay.layers.set(this.layer);
        this.scene.add(this.overlay);
    }
}
