import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

const xPosRange = [35, -35];
const zPosRange = [4, 40];

const sandColor = "#733203";
const windColor = "#ffffff";
const windTrail = 35;

export default class Wind {
    constructor(dev, isSand) {
        this.dev = dev;
        this.isSand = isSand;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        //Resource
        this.resources = this.experience.resources;
        this.resource = this.resources.items.Wind;
        this.resource.magFilter = THREE.NearestFilter;

        this.elapsed = 0;
        this.counter = 0; // for toggling visibility
        this.needsUpdate = false; // for toggling visibility

        // Sin Stuff
        if (this.isSand) {
            this.color = sandColor;
            this.trailCount = 0;

            const lengthNoise = Math.random() * 0.001 - 0.001 / 2;
            this.sinLength = 0.0002 + lengthNoise; // bigger is faster
            const sinHeightNoise = Math.random() * 0.2 - 0.2 / 2;
            this.sinHeight = 1.5 + sinHeightNoise;
            this.particleBaseHeight = 2;

            this.baseTransparency = 0.9;
            this.maxDisplayTime = 13.5; // bigger is slower
        } else {
            this.color = windColor;
            this.trailCount = windTrail;

            const lengthNoise = Math.random() * 0.001 - 0.001 / 2;
            this.sinLength = 0.002 + lengthNoise; // bigger is faster
            const sinHeightNoise = Math.random() * 0.2 - 0.2 / 2;
            this.sinHeight = 0.7 + sinHeightNoise;
            this.particleBaseHeight = 1.5;

            this.baseTransparency = 0.7;
            this.maxDisplayTime = 14; // bigger is slower
        }

        // Speed and Size
        this.particleSize = 0.15;

        const devSizeFactor = 2.5;
        if (this.dev) {
            this.particleSize /= devSizeFactor;
            this.particleBaseHeight /= devSizeFactor;
            this.sinHeight /= devSizeFactor;
            this.sinLength *= 1.3;
        }

        // this.maxDisplayTime = 200 // bigger is slower
        this.moveSpeed = this.particleSize / 12;

        this.randomTimeOffset1 = Math.random() * 3000;

        this.group = new THREE.Group();
        this.setSprite();
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: this.resource })
        );
        this.sprite.layers.set(this.experience.world.HOME_LAYER);

        this.sprite.position.y = 2;
        this.sprite.scale.set(
            this.particleSize,
            this.particleSize,
            this.particleSize
        );
        this.sprite.material.transparent = true;
        this.sprite.material.opacity = this.baseTransparency;

        //Set trails
        if (this.trailCount > 0) {
            this.trailParticles = [this.trailCount];
            for (let x = 0; x < this.trailCount; x++) {
                this.trailParticles[x] = new THREE.Sprite(
                    new THREE.SpriteMaterial({ map: this.resource })
                );
                this.trailParticles[x].layers.set(
                    this.experience.world.HOME_LAYER
                );
                this.trailParticles[x].position.x = 100;
                this.trailParticles[x].position.y = 2;
                this.trailParticles[x].scale.set(
                    this.particleSize,
                    this.particleSize,
                    this.particleSize
                );

                this.trailParticles[x].material.transparent = true;
                this.trailParticles[x].material.opacity =
                    (1 / this.trailCount) *
                    (this.trailCount - x) *
                    this.baseTransparency;

                this.group.add(this.trailParticles[x]);
            }
        }

        if (this.dev) {
            if (this.isSand) {
                const randomOffset = Math.floor(Math.random() * 25);
                const x =
                    zPosRange[0] +
                    Math.random() * (xPosRange[1] - xPosRange[0]) +
                    randomOffset;
                this.group.position.set(x, 0, -4);
            } else {
                this.group.position.set(4, 0, -4);
            }
        } else {
            this.newZPosition();
            const randomOffset = Math.floor(Math.random() * 25);
            this.group.position.x =
                zPosRange[0] +
                Math.random() * (xPosRange[1] - xPosRange[0]) +
                randomOffset;
        }

        this.group.add(this.sprite);
        this.scene.add(this.group);
    }

    newZPosition() {
        this.group.position.z =
            zPosRange[0] + Math.random() * (zPosRange[1] - zPosRange[0]);
    }

    updateBrightness() {
        const newColor = GetColorBetweenColors(
            "#000000",
            this.color,
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.sprite.material.color.set(newColor);
        for (let x = 0; x < this.trailCount; x++) {
            this.trailParticles[x].material.color.set(newColor);
        }
    }

    update() {
        this.updateBrightness();

        this.elapsed += this.time.delta;
        if (this.maxDisplayTime > 0 && this.elapsed >= this.maxDisplayTime) {
            this.elapsed = 0;

            if (this.trailCount > 0) {
                // Update trail particle positions
                for (let x = this.trailCount - 1; x >= 0; x--) {
                    if (x == 0) {
                        this.trailParticles[x].position.y =
                            this.sprite.position.y;
                    } else {
                        this.trailParticles[x].position.y =
                            this.trailParticles[x - 1].position.y;
                    }
                }

                // Set position for the first time.
                if (this.counter < this.trailCount && this.needsUpdate) {
                    this.needsUpdate = false;
                    this.trailParticles[this.counter].position.x =
                        this.particleSize * (this.counter + 1);
                    this.counter++;
                }
            }

            // Update group position.
            this.group.position.x += -this.moveSpeed * this.time.delta;
            this.sprite.position.y =
                Math.sin(
                    (this.time.elapsed + this.randomTimeOffset1) *
                        this.sinLength
                ) *
                    this.sinHeight +
                this.particleBaseHeight;
            this.needsUpdate = true;
        }

        // Reset x position when out of screen.
        if (this.group.position.x < xPosRange[1]) {
            if (!this.dev) {
                this.newZPosition();
            }
            const randomOffset = Math.floor(Math.random() * 25);
            this.group.position.x = xPosRange[0] + randomOffset;
        }
    }
}
