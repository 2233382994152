import Experience from "../../Experience.js";
import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 26315000;
// const SCALE_VALUE = 26315000;

export default class Moon extends CompareObject {
    constructor() {
        super();

        this.experience = new Experience();
        this.time = this.experience.time;

        this.model = this.resources.items.Moon.scene.clone();
        super.setModel();
        this.setPosition();
    }

    setPosition() {
        this.model.position.set(20000000, 26000000, -5000);
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }

    update() {
        // Rotate
        this.model.rotation.y += 0.00005 * this.time.delta;
    }
}
