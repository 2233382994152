import * as THREE from "three";
import Experience, { SCENE_FAQ, SCENE_HOME, SCENE_WHO } from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import TWEEN from "@tweenjs/tween.js";

const X_RANGE = [4, 55];
const Y_RANGE = [1.5, 2.5];
const Z_RANGE = [-0.1, 0];

export default class Camera {
    constructor() {
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;

        // Scroll-adjusted Values
        this.yPositionRange = Y_RANGE[1] - Y_RANGE[0];
        this.zPositionRange = X_RANGE[1] - X_RANGE[0];
        this.xRotationRange = Z_RANGE[1] - Z_RANGE[0];

        this.setInstance();

        // Orbit Controls
        // this.setOrbitControls()
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            100
        );
        this.instance.position.set(0, Y_RANGE[0], X_RANGE[0]);
        this.instance.rotation.x = Z_RANGE[0];

        this.scene.add(this.instance);
    }

    setOrbitControls() {
        this.orbitControlsEnabled = true;
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    tweenCamera(targetPosition, duration) {
        // controls.enabled = false;

        new TWEEN.Tween(this.instance.position)
            .to(
                {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }

    update() {
        if (this.orbitControlsEnabled) {
            this.controls.update();
            return;
        }

        if (this.experience.activeScene == SCENE_HOME) {
            this.instance.position.x +=
                (0 - this.instance.position.x) * this.time.delta * 0.005;
            this.instance.position.z +=
                (X_RANGE[0] +
                    this.scroll.scrollProgress * this.zPositionRange -
                    this.instance.position.z) *
                this.time.delta *
                0.005;
            this.instance.position.y +=
                (Y_RANGE[0] +
                    this.scroll.scrollProgress * this.yPositionRange -
                    this.instance.position.y) *
                this.time.delta *
                0.005;
            this.instance.rotation.x +=
                (Z_RANGE[0] +
                    this.scroll.scrollProgress * this.xRotationRange -
                    this.instance.rotation.x) *
                this.time.delta *
                0.005;
        }
    }
}
