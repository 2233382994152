const hexToDecimal = (hex) => parseInt(hex, 16);

var decToHex = function (x) {
    x = x.toString(16);
    return x.length == 1 ? "0" + x : x;
};

export default function GetColorBetweenColors(color1, color2, value) {
    const color1r = hexToDecimal(color1.substring(1, 3));
    const color1g = hexToDecimal(color1.substring(3, 5));
    const color1b = hexToDecimal(color1.substring(5, 7));

    const color2r = hexToDecimal(color2.substring(1, 3));
    const color2g = hexToDecimal(color2.substring(3, 5));
    const color2b = hexToDecimal(color2.substring(5, 7));

    const r = decToHex(Math.floor(color1r + (color2r - color1r) * value));
    const g = decToHex(Math.floor(color1g + (color2g - color1g) * value));
    const b = decToHex(Math.floor(color1b + (color2b - color1b) * value));

    return "#" + r + g + b;
}
