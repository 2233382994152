import * as THREE from "three";
import Experience from "../Experience.js";
import { SpriteAnimator } from "../Utils/SpriteAnimator.js";

const SCALE = 2;
const POS_X_Y_Z = [-0.21, 6.51, 5.01];
const MAX_OPACITY = [0.75, 1, 1, 0.9];

export default class Emotion {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        this.showingEmotion = [false, false, false, false];

        // Shared Resources
        this.blushResource = this.experience.resources.items.blush;
        this.blushResource.magFilter = THREE.NearestFilter;
        this.blushResource.minFilter = THREE.NearestFilter;

        this.rageResource = this.experience.resources.items.rage;
        this.rageResource.magFilter = THREE.NearestFilter;
        this.rageResource.minFilter = THREE.NearestFilter;

        this.sadResource = this.experience.resources.items.sad;
        this.sadResource.magFilter = THREE.NearestFilter;
        this.sadResource.minFilter = THREE.NearestFilter;

        this.shinyResource = this.experience.resources.items.shiny;

        this.setSprite();
    }

    showEmotion(emotion) {
        this.showingEmotion.forEach((element, index) => {
            this.showingEmotion[index] = false;
        });

        if (emotion >= 0 && emotion < this.showingEmotion.length) {
            this.showingEmotion[emotion] = true;
        }
    }

    updateShadow() {
        // Do nothing.
    }

    setSprite() {
        const blushSprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: this.blushResource,
            })
        );
        const rageSprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: this.rageResource,
            })
        );
        const sadSprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: this.sadResource,
            })
        );

        this.shinySpriteAnimator = new SpriteAnimator(
            this.shinyResource,
            6,
            1,
            this
        );
        const framesToLoop = [0, 1, 2, 3, 4, 5];
        this.shinySpriteAnimator.loop(framesToLoop, 650);

        this.sprites = [
            blushSprite,
            rageSprite,
            sadSprite,
            this.shinySpriteAnimator.sprite,
        ];

        this.sprites.forEach((sprite) => {
            sprite.layers.set(this.experience.world.HOME_LAYER);
            sprite.scale.set(SCALE, SCALE, SCALE);
            sprite.material.opacity = 0;
            sprite.position.set(POS_X_Y_Z[0], POS_X_Y_Z[1], POS_X_Y_Z[2]);
            this.scene.add(sprite);
        });

        // Adjust shiny sprite
        const adjustedScale = SCALE * 1.3;
        this.sprites[3].scale.set(adjustedScale, adjustedScale, adjustedScale);
        this.sprites[3].position.set(
            POS_X_Y_Z[0],
            POS_X_Y_Z[1] + 0.1,
            POS_X_Y_Z[2]
        );
    }

    update() {
        this.shinySpriteAnimator.update();

        this.showingEmotion.forEach((element, index) => {
            if (
                element &&
                this.sprites[index].material.opacity < MAX_OPACITY[index]
            ) {
                this.sprites[index].material.opacity +=
                    (MAX_OPACITY[index] -
                        this.sprites[index].material.opacity) *
                    this.time.delta *
                    0.005;
            } else if (!element && this.sprites[index].material.opacity > 0) {
                this.sprites[index].material.opacity -=
                    this.sprites[index].material.opacity *
                    this.time.delta *
                    0.005;
            }
        });
    }
}
