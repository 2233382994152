import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

const darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });

export default class Sky {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        this.setGeometry();
        this.setTextures();
        this.setMaterial();
        this.setMesh();
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(200, 40);
    }

    setTextures() {
        this.textures = {};

        this.textures.color = this.resources.items.Sunset;
    }

    setMaterial() {
        this.material = new THREE.MeshBasicMaterial({
            map: this.textures.color,
        });
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material);

        this.mesh.position.z = -30;
        this.mesh.position.y = 12;
        this.mesh.layers.enable(this.experience.world.HOME_BG);

        this.scene.add(this.mesh);
    }

    update() {
        const newColor = GetColorBetweenColors(
            "#4d4d4d",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.mesh.material.color.set(newColor);
    }

    darkenMaterial() {
        this.oldMaterial = this.mesh.material;
        this.mesh.material = darkMaterial;
    }

    restoreMaterial() {
        this.mesh.material = this.oldMaterial;
    }
}
