import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 525;

export default class Pyramid extends CompareObject {
    constructor() {
        super();

        this.model = this.resources.items.Pyramid.scene.clone();
        super.setModel();
        this.setPosition();
    }

    setPosition() {
        this.model.position.set(2000, 0, -3500);
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }
}
