import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

export default class Sun {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;
        this.scroll = this.experience.scroll;

        // Scroll-adjusted Values
        this.yPositionRanges = [-5, 26];
        this.yPositionRange = this.yPositionRanges[1] - this.yPositionRanges[0];

        this.sunStartColor = "#8a0000";
        this.sunEndColor = "#ffb121";

        this.middleProgressValue = 0.6;

        this.group = new THREE.Group();

        this.setModel();
    }

    setModel() {
        this.mesh = new THREE.Mesh(
            new THREE.SphereGeometry(6, 50, 50),
            new THREE.MeshBasicMaterial({ color: this.sunStartColor })
        );
        this.group.position.set(0, this.yPositionRanges[0], -30);

        this.mesh.layers.enable(this.experience.world.HOME_BG);

        this.group.add(this.mesh);
        this.scene.add(this.group);
    }

    update() {
        this.group.position.y +=
            (this.yPositionRanges[0] +
                this.scroll.scrollProgress * this.yPositionRange -
                this.group.position.y) *
            this.time.delta *
            0.005;
        const sunColor = GetColorBetweenColors(
            this.sunStartColor,
            this.sunEndColor,
            this.scroll.scrollProgress
        );
        this.mesh.material.color.set(sunColor);
    }
}
