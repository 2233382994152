import Experience, { SCENE_HOME } from "./Experience";

const START_QUOTE_DELAY = 0;
const SPEECH_TICK = 45;
const QUOTE_CLOSE_DELAY = 4000;
const NEW_LINE_DELAY = 250;
const IDLE_QUOTE_DELAY = 10000;
const HOME_IDLE_QUOTE_DELAY = 2000;
const MOBILE_SCARED_STOP_DELAY = 6000;

const COMMENTARY_GREETING = [
    "This is a very important feature.",
    "Wazzup!",
    "Heyo!",
    "Ayo maggots!",
    "Bababooey!",
    "You called?",
    "Hello there.",
    "Oh, hey! Didn't see you there.",
    "Meowdy!",
    "Roger roger.",
];

const COMMENTARY_SCARED = [
    "Let's not be hasty, now...",
    "Whoa whoa whoa! Hold up!",
    "Hey! Watch where you point that thing!",
    "Do it, you won't!",
    "Put that thing back where it came from or so help me!",
    "No! Don't do it.",
    "Please. I've got a wife and kids!",
    "I don't want no trouble.",
    "My time has come.",
    "Wait! I'm not ready!",
    "Calm down, buddy.",
    "What do you think you're doing?",
    "I'm afraid I can't let you do that.",
    "You think I'm scared?",
    "I'm literally shaking right now.",
];

const COMMENTARY_WHEW = [
    "Yep. That's what's I thought.",
    "Whew. That was close.",
    "<3",
    ";)",
    "Thanks for sparing me.",
    "You'll never do it.",
    "I'll remember this.",
    "I'm saved.",
    "Don't scare me like that.",
    "How rude!",
    "Unbelievable!",
    "It's too easy.",
    "Can't believe that worked!",
    "I am in your debt.",
    "You think this is bad? |This Chicanery!",
];

const COMMENTARY_IDLE = [
    "Beep boop.",
    "My commentary is very important.",
    "Think I'll ever make it outta this small town?",
    "Do you get to the cloud district very often? |What am I saying, of course you don't.",
    "Have you heard of the high elves?",
    "Are you going to buy something or wuuut?",
    "My eyes are down here.",
    "Notice me!",
    "o// | \\\\o | o// | \\\\o | \\o/",
];

const COMMENTARY_HOME_SCENE = [
    [
        "So lonely...",
        "Such looming shadows...",
        "Darkness consumes us!",
        "Spooky!",
    ],
    [
        "The shadows... they're shrinking!",
        "Wait... We're not alone!",
        "It's an arch!",
        "Who are they!?",
    ],
    [
        "There are dozens of us!",
        "It's starting to bright up!",
        "The sun's rising!",
        "Pretty...",
    ],
    [
        "Anything is possible!",
        "We can do anything!",
        "We're headed west! Baby!",
        "Let's goooo!",
        "So inspiring!",
    ],
];

const COMMENTARY_SCROLL_FINISHED = ["Tada!"];

export default class DevCommentary {
    constructor() {
        this.experience = new Experience();
        this.time = this.experience.time;
        this.scroll = this.experience.scroll;

        this.commentaryStatus = false;

        this.currentQuote = "";
        this.playingQuote = false;
        this.showingSpeechBubble = false;
        this.scared = false;
        this.elapsed = 0;
        this.idleElapsed = 0;
        this.lastRand = -1;

        // First time hitting scroll = 1
        this.firstScrollFinished = false;

        this.quoteTimeouts = [];

        this.scaredTimeouts = [];

        this.setElements();
    }

    addScaredTimeout(id) {
        this.scaredTimeouts.push(id);
    }

    clearScaredTimeouts() {
        for (let i = 0; i < this.scaredTimeouts.length; i++) {
            clearTimeout(this.scaredTimeouts[i]);
        }
        this.scaredTimeouts = [];
    }

    addQuoteTimeout(id) {
        this.quoteTimeouts.push(id);
    }

    clearQuoteTimeouts() {
        for (let i = 0; i < this.quoteTimeouts.length; i++) {
            clearTimeout(this.quoteTimeouts[i]);
        }
        this.quoteTimeouts = [];
    }

    showBubble(show) {
        if (show) {
            this.showingSpeechBubble = true;
            this.commentaryBubble.classList.add("visible");
        } else {
            this.showingSpeechBubble = false;
            this.commentaryBubble.classList.remove("visible");
            this.elapsed = 0;
            this.idleElapsed = 0;
            this.playingQuote = false;
        }
    }

    playHomeQuote() {
        // Play correct quote based on scroll position.
        let quoteIndex = 0;
        const x = this.scroll.trueProgress;

        if (x < 0.06) {
            quoteIndex = 0;
        } else if (x < 0.2) {
            quoteIndex = 1;
        } else if (x < 0.5) {
            quoteIndex = 2;
        } else {
            quoteIndex = 3;
        }

        this.playQuote(COMMENTARY_HOME_SCENE[quoteIndex]);
    }

    playQuote(quoteArray, scaredPlay) {
        // If not playing quote from scared, reset scared.
        if (!scaredPlay) {
            this.scared = false;
            this.commentaryPortrait.classList.remove("scared");
            this.clearScaredTimeouts();
        }

        this.clearQuoteTimeouts();
        this.idleElapsed = 0;

        // Prevent repeat.
        let rand = Math.floor(Math.random() * quoteArray.length);
        if (rand == this.lastRand) {
            rand = (rand + 1) % quoteArray.length;
        }
        this.lastRand = rand;

        const quote = quoteArray[rand];
        this.quoteToPlay = quote;

        this.currentQuote = "";
        this.commentaryBubble.innerHTML = "";

        // let timeoutID = window.setTimeout(() => {
        this.showBubble(true);
        this.playingQuote = true;
        // }, START_QUOTE_DELAY);
        // this.addQuoteTimeout(timeoutID);
    }

    updateQuote(lettersToAdd) {
        if (this.currentQuote.length < this.quoteToPlay.length) {
            let newChar = this.quoteToPlay.substring(
                this.currentQuote.length,
                this.currentQuote.length + lettersToAdd
            );
            // Add next character too if space.
            if (newChar == " ") {
                newChar += this.quoteToPlay.substring(
                    this.currentQuote.length + 1,
                    this.currentQuote.length + lettersToAdd + 1
                );
            }

            // Add delay if new line.
            if (newChar.includes("|")) {
                this.elapsed -= NEW_LINE_DELAY;
            }
            this.currentQuote += newChar;
            this.commentaryBubble.innerHTML = this.currentQuote.replaceAll(
                "|",
                "<br/>"
            );
        }
        if (this.currentQuote.length == this.quoteToPlay.length) {
            this.playingQuote = false;

            // Hide quote after awhile.
            let timeoutID = window.setTimeout(() => {
                this.showBubble(false);
            }, QUOTE_CLOSE_DELAY);
            this.addQuoteTimeout(timeoutID);
        }
    }

    showButton(show) {
        if (show) {
            this.commentaryButton.classList.add("visible");
        } else {
            this.commentaryButton.classList.remove("visible");
        }
    }

    showContainer(show) {
        if (show) {
            this.commentaryContainer.classList.add("visible");
        } else {
            this.commentaryContainer.classList.remove("visible");
        }
    }

    setElements() {
        // Commentary Container
        this.commentaryContainer = document.querySelector(
            "#commentaryContainer"
        );

        // Add vertical offset to commentary for mobile.
        if (this.experience.isMobile) {
            this.commentaryContainer.classList.add("mobile-offset");
        }

        // Commentary Button
        this.commentaryButton = document.querySelector("#commentaryButton");
        this.commentaryButton.addEventListener("click", () => {
            this.showCommentary(!this.commentaryStatus);
        });

        // Button Text
        this.buttonText = document.querySelector("#commentaryButtonText");

        // Commentary Bubble
        this.commentaryBubble = document.querySelector("#commentaryBubble");
        this.commentaryBubble.addEventListener("click", () => {
            // Hide bubble.
            this.showBubble(false);
        });

        // Portrait
        this.commentaryPortrait = document.querySelector("#commentaryPortrait");

        if (this.experience.isMobile) {
            // Mobile Interaction

            // Click to enter scared mode. Click again to disable.
            this.commentaryPortrait.addEventListener("click", () => {
                if (this.scared) {
                    // Disable commentary.
                    this.showCommentary(false);
                } else {
                    this.scared = true;
                    this.commentaryPortrait.classList.add("scared");
                    this.playQuote(COMMENTARY_SCARED, true);

                    // Hide bubble after awhile.
                    const timeoutID = window.setTimeout(() => {
                        if (this.scared) {
                            this.scared = false;
                            this.commentaryPortrait.classList.remove("scared");
                            this.playQuote(COMMENTARY_WHEW);
                        }
                    }, MOBILE_SCARED_STOP_DELAY);
                    this.addScaredTimeout(timeoutID);
                }
            });
        } else {
            // PC Interaction

            // Click to disable.
            this.commentaryPortrait.addEventListener("click", () => {
                // Disable commentary.
                this.showCommentary(false);
            });

            // Mouseover to enter scared mode.
            this.commentaryPortrait.addEventListener("mouseover", () => {
                this.scared = true;
                this.commentaryPortrait.classList.add("scared");
                this.playQuote(COMMENTARY_SCARED, true);
            });

            // Mouseout to exit scared mode.
            this.commentaryPortrait.addEventListener("mouseout", () => {
                this.scared = false;
                this.commentaryPortrait.classList.remove("scared");
                this.playQuote(COMMENTARY_WHEW);
            });
        }

        this.showCommentary(this.commentaryStatus);
    }

    showCommentary(show) {
        this.clearScaredTimeouts();
        this.scared = false;
        this.commentaryPortrait.classList.remove("scared");

        this.commentaryStatus = show;
        if (show) {
            this.showContainer(true);
            this.buttonText.innerHTML = "Developer Commentary: ON";

            // Play greeting.
            this.playQuote(COMMENTARY_GREETING);
        } else {
            this.showContainer(false);
            this.buttonText.innerHTML = "Developer Commentary: OFF";

            // Hide bubble.
            this.showBubble(false);
        }
    }

    update() {
        if (!this.firstScrollFinished && this.scroll.scrollProgress == 1) {
            this.playQuote(COMMENTARY_SCROLL_FINISHED);
            this.firstScrollFinished = true;
        }

        // Play quote.
        if (this.playingQuote) {
            this.elapsed += this.time.delta;
            if (this.elapsed > SPEECH_TICK) {
                const lettersToAdd = Math.floor(this.elapsed / SPEECH_TICK);
                this.elapsed = this.elapsed - SPEECH_TICK * lettersToAdd;
                this.updateQuote(lettersToAdd);
            }
        } else if (
            !this.showingSpeechBubble &&
            !this.scared &&
            this.commentaryStatus
        ) {
            // Idle commentary.
            this.idleElapsed += this.time.delta;

            // Play home scene commentary.
            if (
                this.experience.activeScene == SCENE_HOME &&
                this.scroll.scrollProgress < 1 &&
                this.idleElapsed > HOME_IDLE_QUOTE_DELAY
            ) {
                this.playHomeQuote();
            } else if (this.idleElapsed > IDLE_QUOTE_DELAY) {
                // Default idle.
                this.playQuote(COMMENTARY_IDLE);
            }
        }
    }
}
