import Experience from "../Experience";

const PREVIEW_IMAGES = 12;
const PREVIEW_COLORS = [
    "#de5200",
    "#6c00a3",
    "#e6d65f",
    "#c7c7c7",
    "#b7e86f",
    "#83dbcb",
    "#3574c2",
    "#bd7ceb",
    "#d95860",
    "#d98f58",
    "#64ad71",
    "#e89de4",
];

const COMPARE_ITEMS = [
    // Name, [Quotes], Scale, HeightCM, [Title, Text, Credits]
    [
        "Banana",
        [
            "I know the number one exporter!",
            "Ring ring ring ring ring ring ring.",
            "Banana Phone!",
            "So dangerously yummy...",
            "So rich in fiber...",
            "Also a good source of Vitamin B6 and C!",
        ],
        1,
        [
            "Fun Potassium Source",
            "Weighs up to 4 wildwesters when ripe.",
            "grafi",
        ],
    ],
    [
        "Emperor Penguin",
        [
            "Waddle waddle.",
            "Hello, good sir!",
            "Happy feet!",
            "So fancy!",
            "Magnificent!",
            "So cute!",
        ],
        8,
        [
            "The Biggest Bird",
            "Consumes 70 wildwesters in weight of fish everyday.",
            "Noah Hahn",
        ],
    ],
    [
        "African Elephant",
        [
            "Save the elephants!",
            "So majestic...",
            "Woah!",
            "*elephant noises*",
            "So wise...",
            "So nosy!",
        ],
        30,
        [
            "Nosiest Animal Alive",
            "Weighs up to 160,000 wildwesters.",
            "onderonvermez",
        ],
    ],
    [
        "Great Pyramid of Giza",
        [
            "Tell me your secrets!",
            "So mysterious...",
            "We built the pyramids!",
            "So wonderful...",
            "So ancient...",
            "Belongs in a museum!",
            "Behold!",
            "Visible from pizzahut!",
        ],
        800,
        [
            "Ancient Wonder",
            "Fits more than 2.6 billion wildwesters inside.",
            "MarloesB",
        ],
    ],
    [
        "Eiffel Tower",
        [
            "Je suis enchanté!",
            "Où est le bibliothèque?",
            "Voilà mon passport.",
            "Ah, Gérard Depardieu!",
            "Baguette, hon hon hon!",
            "Foux du fafa!",
            "Pamplemousse!",
            "Ananas!",
            "Jus d'orange!",
            "Soupe du jour!",
            "Camembert!",
        ],
        3000,
        ["La dame de fer", "Over 4000 wildwesters tall.", "SDC PERFORMANCE™️"],
    ],
    [
        "Mt. Everest",
        [
            "I'm at the top of the world!",
            "Brrrrrr!",
            "So cold...",
            "So high...",
        ],
        50000,
        [
            "Very Tall Mountain",
            "First summited 70 years before the creation of wildwesters.",
            "Nate Loper",
        ],
    ],
    [
        "The Moon",
        [
            "Fly me to the moon!",
            "Everyone knows it's made of cheese.",
            "So beautiful...",
            "To the moon!",
            "Ground control to major tom...",
            "So mysterious...",
            "Awoooooo!",
            "That's more than 3300 great pyramid of gizas of wildwesters!",
        ],
        12000000,
        [
            "Luna",
            "Takes 6.6 trillion wildwesters to cover the entire surface.",
            "SebastianSosnowski",
        ],
    ],
];

const QR_LINKS = [
    "https://app.wildwesters.io/wildwester/1",
    "https://app.wildwesters.io/wildwester/19",
    "https://app.wildwesters.io/wildwester/34",
    "https://app.wildwesters.io/wildwester/45",

    "https://app.wildwesters.io/wildwester/59",
    "https://app.wildwesters.io/wildwester/62",
    "https://app.wildwesters.io/wildwester/84",
    "https://app.wildwesters.io/wildwester/96",

    "https://app.wildwesters.io/wildwester/104",
    "https://app.wildwesters.io/wildwester/115",
    "https://app.wildwesters.io/wildwester/121",
    "https://app.wildwesters.io/wildwester/137",
];

const OPEN_STAND_BUTTON_TEXT = "RISE UP!";
const CLOSE_STAND_BUTTON_TEXT = "GET DOWN!";
const OKAY_STAND_BUTTON_TEXT = "UH... OKAY!";

// Dev Commentary
const COMMENTARY_PAGE_CHANGE = [
    ["Wow!", "Amazing!", "Legendary!"],
    ["It... changes!?", "Impossible...", "It's moving! What!?"],
    ["So epic...", "So digital...", "So Phenomenal!", "I'm speechless..."],
    [
        "Yes Yes Yes!",
        "Standtech!",
        "Brilliant!",
        "He did it. The mad genius, he really did it!",
    ],
    ["So dimensional...", "So specific...", "Just like real life!"],
];

const COMMENTARY_PREVIEW = [
    ["#1. Yeeeeehaw!"],
    ["#19. FUUUTURE!"],
    ["#34. Yeeellow!"],
    ["#45. Kyaa~ Kawai!"],
    ["#59. *honk honk*"],
    ["#62. Arrrgh!!"],
    ["#84. Weewoo weewoo!"],
    ["#96. Pew!"],
    ["#104. Zug."],
    ["#115. Beep boop."],
    ["#121. So romantic..."],
    ["#137. It's pink!"],
];

const COMMENTARY_FEATURES = [
    ["Start collecting today!"],
    ["I wanna be the very best!", "You know it's my destiny!"],
    ["Family. Family. Family.", "One of us! One of us!"],
    ["Historical!"],
];

const COMMENTARY_STAND_ACTIONS = [
    ["Righteous!"],
    ["Get Down!"],
    [
        "Rise up!",
        "Rise, my glorious creation. Rise!",
        "Get up! Don't give up!",
        "Legends never die!",
    ],
];

export default class ProductPageController {
    constructor() {
        this.experience = new Experience();
        this.camera = this.experience.camera2;
        this.time = this.experience.time;

        this.currentPage = 0;
        this.currentPreview = 0;
        this.standOpenStatus = true;

        this.currentCompare = 0;
        this.page4State = 0;

        this.setElements();
        this.setPageController();
    }

    showElement(element, show) {
        if (show) {
            element.classList.add("visible");
        } else {
            element.classList.remove("visible");
        }
    }

    showPointerEvent(element, show) {
        if (show) {
            element.classList.add("autopointer");
        } else {
            element.classList.remove("autopointer");
        }
    }

    pageSpecificStuff(index) {
        if (index == 3) {
            this.page4State = 0;
            this.tryButtonLabel.innerHTML = CLOSE_STAND_BUTTON_TEXT;
            this.experience.product.product.outlineStand(true);
        } else {
            // Annoying page 4 stuff.
            this.page4description2.classList.remove("visible");
            this.page4description3.classList.remove("visible");
            this.page4description1.classList.add("delay-2");
            this.page4description1.classList.add("visible");

            this.experience.product.product.outlineStand(false);
            if (!this.standOpenStatus) {
                this.experience.product.product.openStand(true);
                this.experience.product.environment.brightLight(true);
            }
        }

        if (index == 4) {
            this.showCompare(0, true);
        } else {
            this.showCompare(-1);
            this.experience.product.environment.scalePointLight(1);
            this.experience.product.product.setScale(1);
        }
    }

    showPage(index, skipTween) {
        for (let i = 0; i < this.pageDots.length; i++) {
            if (i === index) {
                this.pageDots[i].classList.add("selected");
            } else {
                this.pageDots[i].classList.remove("selected");
            }
        }
        for (let i = 0; i < this.pageCircles.length; i++) {
            if (i === index) {
                this.pageCircles[i].classList.add("selected");
            } else {
                this.pageCircles[i].classList.remove("selected");
            }
        }

        // Play Commentary
        if (index != -1) {
            this.experience.playCommentary(COMMENTARY_PAGE_CHANGE[index]);
        }

        // Page Specific Stuff. Must do before showing elements.
        this.pageSpecificStuff(index);

        // Show Page Elements
        this.pageElements.forEach((elementArray) => {
            elementArray.forEach((element) => {
                this.showElement(element, false);
            });
        });

        // Hide Page Pointer Elements
        this.pagePointerElements.forEach((elementArray) => {
            elementArray.forEach((element) => {
                this.showPointerEvent(element, false);
            });
        });

        if (index >= 0 && index < this.pageElements.length) {
            this.currentPage = index;
            this.camera.setTarget(index, !skipTween);

            // Show elements.
            this.pageElements[index].forEach((element) => {
                this.showElement(element, true);
            });
            // Show pointer elements.
            this.pagePointerElements[index].forEach((element) => {
                this.showPointerEvent(element, true);
            });
        }
    }

    showPageController(show) {
        if (show) {
            this.pageController.classList.add("visible");
        } else {
            this.pageController.classList.remove("visible");
        }
    }

    mouseOverPage(index) {
        this.pageCircles[index].classList.add("selected");
        this.pageCircles[this.currentPage].classList.remove("selected");
    }

    mouseOutPage(index) {
        this.pageCircles[index].classList.remove("selected");
        this.pageCircles[this.currentPage].classList.add("selected");
    }

    page4TryButton() {
        this.page4State = (this.page4State + 1) % 3;
        this.standOpenStatus = this.page4State == 0 || this.page4State == 2;
        this.page4description1.classList.remove("delay-2");

        // Dev Commentary
        this.experience.playCommentary(
            COMMENTARY_STAND_ACTIONS[this.page4State]
        );

        if (this.page4State == 0) {
            this.experience.product.environment.brightLight(true);

            this.tryButtonLabel.innerHTML = CLOSE_STAND_BUTTON_TEXT;
            this.camera.setTarget(3, true, 2500);

            this.page4description1.classList.add("visible");
            this.page4description2.classList.remove("visible");
            this.page4description3.classList.remove("visible");
        } else if (this.page4State == 1) {
            this.experience.product.environment.brightLight(false);

            this.tryButtonLabel.innerHTML = OPEN_STAND_BUTTON_TEXT;
            this.experience.product.product.openStand(false);
            this.camera.page4Position(0);

            this.page4description1.classList.remove("visible");
            this.page4description2.classList.add("visible");
            this.page4description3.classList.remove("visible");
        } else if (this.page4State == 2) {
            this.experience.product.environment.brightLight(true);

            this.tryButtonLabel.innerHTML = OKAY_STAND_BUTTON_TEXT;
            this.experience.product.product.openStand(true);
            this.camera.page4Position(1);

            this.page4description1.classList.remove("visible");
            this.page4description2.classList.remove("visible");
            this.page4description3.classList.add("visible");
        }
    }

    setPageController() {
        this.pageController = document.querySelector("#pageController");
        this.pageDots = [];
        this.pageCircles = [];

        for (let i = 0; i < this.pageElements.length; i++) {
            const pageDot = document.createElement("div");
            pageDot.classList.add("page-dot");
            pageDot.addEventListener("click", () => {
                if (this.currentPage != i) {
                    this.showPage(i, false);
                }
            });

            const pageCircle = document.createElement("div");
            pageCircle.classList.add("page-circle");

            pageDot.addEventListener("mouseover", () => {
                if (i != this.currentPage) {
                    this.mouseOverPage(i);
                }
            });

            pageDot.addEventListener("mouseout", () => {
                if (i != this.currentPage) {
                    this.mouseOutPage(i);
                }
            });

            pageDot.appendChild(pageCircle);
            this.pageController.appendChild(pageDot);

            this.pageDots.push(pageDot);
            this.pageCircles.push(pageCircle);
        }
    }

    mouseOverPreview(index) {
        this.camera.previewMode(true);
        this.experience.changeProductPreview(index);

        if (index != this.currentPreview) {
            this.previewCircles[index].classList.add("selected");
            this.previewCircles[this.currentPreview].classList.remove(
                "selected"
            );
        }
    }

    mouseOutPreview(index) {
        this.camera.previewMode(false);
        this.experience.changeProductPreview(this.currentPreview);

        if (index != this.currentPreview) {
            this.previewCircles[index].classList.remove("selected");
            this.previewCircles[this.currentPreview].classList.add("selected");
        }
    }

    showPreview(index) {
        this.currentPreview = index;
        this.experience.changeProductPreview(index);

        for (let i = 0; i < this.previewDots.length; i++) {
            if (i === index) {
                this.previewDots[i].classList.add("selected");
            } else {
                this.previewDots[i].classList.remove("selected");
            }
        }

        for (let i = 0; i < this.previewCircles.length; i++) {
            if (i === index) {
                this.previewCircles[i].classList.add("selected");
            } else {
                this.previewCircles[i].classList.remove("selected");
            }
        }
    }

    mouseOverCompare(index) {
        this.compareCircles.forEach((element) => {
            element.classList.remove("selected");
        });

        if (index != this.currentCompare) {
            this.compareCircles[index].classList.add("selected");
        }
    }

    mouseOutCompare(index) {
        this.compareCircles.forEach((element) => {
            element.classList.remove("selected");
        });

        if (index != this.currentCompare) {
            this.compareCircles[this.currentCompare].classList.add("selected");
        }
    }

    showCompare(index, skipCommentary) {
        if (index >= 0 && index < COMPARE_ITEMS.length) {
            this.currentCompare = index;

            if (!skipCommentary) {
                this.experience.playCommentary(COMPARE_ITEMS[index][1]);
            }

            this.experience.product.product.setScale(COMPARE_ITEMS[index][2]);
            this.experience.product.environment.scalePointLight(
                COMPARE_ITEMS[index][2]
            );
        }

        // Show item info and add "picked" class to comapre dot.
        if (index != -1) {
            this.itemInfos.forEach((element) => {
                element.classList.remove("visible");
            });
            this.compareDots.forEach((element) => {
                element.classList.remove("picked");
            });
            this.itemInfos[index].classList.add("visible");
            this.compareDots[index].classList.add("picked");
        }

        // Toggle visibility of objects.
        for (
            let i = 0;
            i < this.experience.product.product.compareObjects.length;
            i++
        ) {
            if (i <= index) {
                this.experience.product.product.compareObjects[i].toggleVisible(
                    true
                );
            } else {
                this.experience.product.product.compareObjects[i].toggleVisible(
                    false
                );
            }
        }
    }

    setCompareController() {
        this.compareDots = [];
        this.compareCircles = [];
        this.itemInfos = [];

        // Create item info.
        for (let i = 0; i < COMPARE_ITEMS.length; i++) {
            const itemInfo = document.createElement("div");
            itemInfo.classList.add("item-info");

            const itemName = document.createElement("div");
            itemName.classList.add("item-name");
            itemName.innerHTML = COMPARE_ITEMS[i][0];

            const itemTitle = document.createElement("div");
            itemTitle.classList.add("item-title");
            itemTitle.innerHTML = COMPARE_ITEMS[i][3][0];

            const itemText = document.createElement("div");
            itemText.classList.add("item-text");
            itemText.innerHTML = COMPARE_ITEMS[i][3][1];

            const itemCredits = document.createElement("div");
            itemCredits.classList.add("item-credits");
            itemCredits.innerHTML = "3D Model by " + COMPARE_ITEMS[i][3][2];

            itemInfo.appendChild(itemName);
            itemInfo.appendChild(itemTitle);
            itemInfo.appendChild(itemText);
            itemInfo.appendChild(itemCredits);

            this.itemInfos.push(itemInfo);
            this.itemInfoContainer.appendChild(itemInfo);
        }

        for (let i = 0; i < COMPARE_ITEMS.length; i++) {
            const compareDot = document.createElement("div");
            compareDot.classList.add("compare-dot");
            compareDot.addEventListener("click", () => {
                if (this.currentCompare != i) {
                    this.showCompare(i);
                }
            });

            const compareCircle = document.createElement("div");
            compareCircle.classList.add("compare-circle");

            if (!this.experience.isMobile) {
                compareDot.addEventListener("mouseover", () => {
                    if (i != this.currentCompare) {
                        this.mouseOverCompare(i);
                    }
                });

                compareDot.addEventListener("mouseout", () => {
                    if (i != this.currentCompare) {
                        this.mouseOutCompare(i);
                    }
                });
            }

            compareDot.appendChild(compareCircle);
            this.compareController.appendChild(compareDot);

            this.compareDots.push(compareDot);
            this.compareCircles.push(compareCircle);
        }

        if (!this.experience.isMobile) {
            this.compareCircles[this.currentCompare].classList.add("selected");
        }
    }

    setPreviewController() {
        this.previewDots = [];
        this.previewCircles = [];

        for (let i = 0; i < PREVIEW_IMAGES; i++) {
            const dot = document.createElement("div");
            dot.classList.add("preview-dot");
            dot.style.backgroundColor = PREVIEW_COLORS[i];

            if (this.experience.isMobile) {
                // Onclick event for mobile.
                dot.addEventListener("click", () => {
                    this.camera.previewMode(false);
                    if (this.currentPreview != i) {
                        this.showPreview(i);
                        this.experience.playCommentary(COMMENTARY_PREVIEW[i]);
                    }
                });
            } else {
                // Onclick event for PC.
                dot.addEventListener("click", () => {
                    if (this.currentPreview != i) {
                        this.showPreview(i);
                    }
                });

                // Mouseover event for PC.
                dot.addEventListener("mouseover", () => {
                    this.mouseOverPreview(i);
                    this.experience.playCommentary(COMMENTARY_PREVIEW[i]);
                });

                // Mouseout event for PC.
                dot.addEventListener("mouseout", () => {
                    this.mouseOutPreview(i);
                });
            }

            const circle = document.createElement("div");
            circle.classList.add("preview-circle");
            dot.appendChild(circle);

            if (i == this.currentPreview) {
                dot.classList.add("selected");
                circle.classList.add("selected");
            }

            this.previewController.appendChild(dot);

            this.previewDots.push(dot);
            this.previewCircles.push(circle);
        }

        this.previewCircles[this.currentPreview].classList.add("selected");
    }

    setElements() {
        this.pageElements = [];
        this.pagePointerElements = [];

        // Page 1
        const page1 = document.querySelector("#page1");
        const title = document.querySelector("#page1Title");
        const page1Description = document.querySelector("#page1Description");
        const page1Elements = [page1, title, page1Description];
        this.pageElements.push(page1Elements);
        const page1PointerElements = [];
        this.pagePointerElements.push(page1PointerElements);

        // Page 2
        const page2 = document.querySelector("#page2");
        const title2 = document.querySelector("#page2Title");
        const page2description1 = document.querySelector("#page2Description1");
        const page2description2 = document.querySelector("#page2Description2");
        this.previewControllerContainer = document.querySelector(
            "#previewControllerContainer"
        );
        this.previewController = document.querySelector("#previewController");
        const page2ExploreButton = document.querySelector(
            "#page2ExploreButton"
        );
        page2ExploreButton.addEventListener("click", () => {
            window.open("https://app.wildwesters.io/collection");
        });
        this.setPreviewController();

        const page2Elements = [
            page2,
            title2,
            page2description1,
            page2description2,
            this.previewControllerContainer,
        ];
        this.pageElements.push(page2Elements);
        const page2PointerElements = [
            this.previewController,
            page2ExploreButton,
        ];
        this.pagePointerElements.push(page2PointerElements);

        // Page 3
        const page3 = document.querySelector("#page3");
        const title3 = document.querySelector("#page3Title");
        const page3description1 = document.querySelector("#page3Description1");
        const page3description2 = document.querySelector("#page3Description2");
        const visitButtonContainer = document.querySelector(
            "#visitButtonContainer"
        );
        const page3VisitButton = document.querySelector("#page3VisitButton");
        page3VisitButton.addEventListener("click", () => {
            window.open(QR_LINKS[this.currentPreview]);
        });
        const page3FeatureContainer = document.querySelector(
            "#page3FeatureContainer"
        );

        // Dev Commentary for feature box.
        const featureBox1 = document.querySelector("#featureBox1");
        const featureBox2 = document.querySelector("#featureBox2");
        const featureBox3 = document.querySelector("#featureBox3");
        const featureBox4 = document.querySelector("#featureBox4");
        // Prevent mouseout playing it again for some reason.
        const featureBoxes = [
            featureBox1,
            featureBox2,
            featureBox3,
            featureBox4,
        ];
        this.lastPlayedFeature = -1;

        // for each feature box
        for (let i = 0; i < featureBoxes.length; i++) {
            featureBoxes[i].addEventListener("mouseover", () => {
                if (
                    this.lastPlayedFeature != i ||
                    !this.experience.devCommentary.showingSpeechBubble
                ) {
                    this.lastPlayedFeature = i;
                    this.experience.playCommentary(COMMENTARY_FEATURES[i]);
                }
            });
        }

        const page3Elements = [
            page3,
            title3,
            page3description1,
            page3description2,
            visitButtonContainer,
            page3FeatureContainer,
        ];
        this.pageElements.push(page3Elements);
        const page3PointerElements = [page3VisitButton, page3FeatureContainer];
        this.pagePointerElements.push(page3PointerElements);

        // Page 4
        const page4 = document.querySelector("#page4");
        const title4 = document.querySelector("#page4Title");
        const page4DescriptionBox = document.querySelector(
            "#page4DescriptionBox"
        );
        this.page4description1 = document.querySelector("#page4Description1");
        this.page4description2 = document.querySelector("#page4Description2");
        this.page4description3 = document.querySelector("#page4Description3");

        const tryButtonContainer = document.querySelector(
            "#tryButtonContainer"
        );
        this.tryButtonLabel = document.querySelector("#tryButtonLabel");
        this.tryButtonLabel.innerHTML = CLOSE_STAND_BUTTON_TEXT;
        const page4TryButton = document.querySelector("#page4TryButton");
        page4TryButton.addEventListener("click", () => {
            this.page4TryButton();
        });

        const page4Elements = [
            page4,
            title4,
            page4DescriptionBox,
            tryButtonContainer,
        ];
        this.pageElements.push(page4Elements);
        const page4PointerElements = [page4TryButton];
        this.pagePointerElements.push(page4PointerElements);

        // Page 5
        const page5 = document.querySelector("#page5");
        const title5 = document.querySelector("#page5Title");
        const page5description1 = document.querySelector("#page5Description1");
        const page5description2 = document.querySelector("#page5Description2");
        this.compareControllerContainer = document.querySelector(
            "#compareControllerContainer"
        );
        this.compareController = document.querySelector("#compareController");
        this.itemInfoContainer = document.querySelector("#itemInfoContainer");
        this.setCompareController();

        const page5Elements = [
            page5,
            title5,
            page5description1,
            page5description2,
            this.compareControllerContainer,
            this.itemInfoContainer,
        ];
        this.pageElements.push(page5Elements);
        const page5PointerElements = [this.compareController];
        this.pagePointerElements.push(page5PointerElements);
    }
}
