import * as THREE from "three";
import Experience from "../Experience.js";

export default class Floor {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.product.scene;
        this.resources = this.experience.resources;

        this.setMaterial();
        this.setMesh();
    }

    setMaterial() {
        this.material = new THREE.MeshStandardMaterial();
        this.material.roughness = 1;
    }

    setMesh() {
        this.plane = new THREE.Mesh(
            new THREE.PlaneGeometry(30, 30),
            this.material
        );
        this.plane.rotation.x = -Math.PI * 0.5;
        this.plane.receiveShadow = true;
        // this.plane.castShadow = true;
        this.scene.add(this.plane);
    }
}
