import * as THREE from "three";

import Experience, {
    SCENE_HOME,
    SCENE_WHAT,
    SCENE_WHO,
    SCENE_FAQ,
} from "../Experience";

const NEXT_QUOTE_DELAY = 7000;
const RESET_QUOTE_DELAY = 12000;
const START_QUOTE_DELAY = 250;
const FIRST_QUOTE_DELAY = 2500;
const SPEECH_TICK = 45;

// Dev Commentary
const COMMENTARY_TEAM = [
    ["He's not actually a street!"],
    ["Hey! That's me!", "Wow! He's so cool!"],
    ["Jack is a legend!"],
];

const devQuotes =
    // John Quotes
    [
        [
            "Dead or alive.",
            "There seem to be no options.",
            "Time to take action!",
            "First... how can I achieve it?",
            "Oh! Wait a minute...",
            "It's time to rest.",
            "Let's take the next step tomorrow.",
            "Sorry. That was a joke.",
            "I must achieve it today.",
            "Today will be a good day to die.",
        ],

        // Eddie Quotes
        [
            "Hey, how the heck are you?",
            "My name is Eddie... |and I'm here to say...",
            "Wow, Great moves! |Keep it up, Proud of you!",
            "Bababooey!",
            "Kept you waiting, huh?",
            "Remind yourself that overconfidence is a slow and insidious killer...",
            "The emperor protects!",
            "Rock and stone!",
            "Family.",
            "Zug zug.",
            "Hey, you. You're finally awake.",
            "You were trying to cross the border, right? ||Walked right into that Imperial ambush, same as us, and that thief over there.",
            "Do you come to the cloud district very often? ||Oh, what am I saying? Of course you don't.",
        ],

        //Jack Quotes
        [
            "Szeto. Jack Szeto.",
            "Life is short, play more.",
            "Today is a good day to die.",
            "Die with zero.",
            "The unseen blade is the deadliest.",
        ],
    ];

const FAQ = [
    [
        "Who are you?",
        "I am Eddie. | The creator of WildWesters. || I am a creative developer based in Hong Kong with a passion for collectibles! || I am also a huge video game nerd and film geek!",
        3,
        ["Whoa, he's just like me!"],
    ],

    [
        "So... what is WildWesters?",
        "It's a collection of 144 unique physical collectibles. || Each one a beautiful lenticular print that belongs to a collectable set and can be digitally tracked across the world! || Owners can find and message each other on our collection app.",
        -1,
        ["Groundbreaking!"],
    ],

    [
        "Why digital tracking?",
        "It allows owners to stay connected with their WildWesters long after they split paths. || With our optional digital tracking, each WildWester’s journey is etched into history. || Imagine if we could trace the journey of each Leonardo da Vinci painting. || That's my vision!",
        -1,
        ["He's certainly no da Vinci!"],
    ],

    [
        "How does it work?",
        "Each wildwester ships with a secret activation code that can be claimed on our collection app. || Once claimed, your account will be displayed as the rightful owner. || Ownership can be transfered on our collection app and a WildWester's location will be shared if allowed.",
        -1,
        ["So simple!"],
    ],

    [
        "What does a WildWester represent?",
        "Someone willing to take a risk, |to leap into the unknown in search for greatness. ||Someone who dares to venture into... ||the Wild Wild West!",
        3,
        ["Yeehaw!"],
    ],

    [
        "Why did you make this?",
        "To inspire anyone out there who feels alone in their journey. ||You're never alone. ||We're all headed west together!",
        0,
        ["That's right!"],
    ],

    [
        "Smells like a sussy NFT...",
        "This project started out as one! ||But the sussy parts turned me off... ||So I made my own trackable, physical collectible! ||100% blockchain free!",
        -1,
        ["How reassuring!"],
    ],

    [
        "How much does one cost?",
        "50 USD ||with FREE global shipping!",
        -1,
        ["What a steal!"],
    ],

    [
        "That's outrageous!",
        "First of all, how dare you? ||Each WildWester is a handcrafted, trackable, labor of collectable love. Each with only one in existence. ||You wouldn't want to be laughed at one day for passing up one of Eddie's priceless WildWester originals, would you? ||Unbelievable.",
        1,
        ["I can't believe you would say that..."],
    ],

    [
        "But why do I need one?",
        "Because you find one cool! ||Or like what it stands for! ||Or just want to support a budding creative! :)",
        3,
        ["I need one now!"],
    ],

    [
        "Hm... that is pretty worth it!",
        "I know right! ||Get yours before they sell out!",
        -1,
        ["Well? We're waiting... "],
    ],

    ["This collection blows.", ":(", 2, ["Take that back!"]],

    ["JK! This is so cool!", "Awww no you! :3", 0, ["That's right!"]],
];

const devPhotos = [
    ["/images/john1.png", "/images/eddie1.png", "/images/jack1.png"],
    ["/images/john2.png", "/images/eddie2.png", "/images/jack2.png"],
];

export default class MainMenu {
    constructor() {
        this.experience = new Experience();
        this.time = this.experience.time;
        this.elapsed = 0;

        this.setButtons();

        this.showingMenu = false;
        this.showingBack = false;
        this.showingDev = false;
        this.showingPageController = false;

        this.currentQuote = "";
        this.quoteIndex = [0, 0, 0];
        this.playingQuote = false;

        this.currentAnswer = "";
        this.newAnswer = "";
        this.playingAnswer = false;

        this.quoteTimeouts = [];
    }

    addQuoteTimeout(id) {
        this.quoteTimeouts.push(id);
    }

    clearQuoteTimeouts() {
        for (let i = 0; i < this.quoteTimeouts.length; i++) {
            clearTimeout(this.quoteTimeouts[i]);
        }
        this.quoteTimeouts = [];
    }

    showMenu(show) {
        if (show) {
            this.menuContainer.classList.remove("nopointer");
            this.whatButton.classList.add("visible");
            this.faqButton.classList.add("visible");
            this.teamButton.classList.add("visible");
            this.collectionButton.classList.add("visible");
            this.instagramButton.classList.add("visible");
            this.whatButton.classList.remove("hide");
            this.faqButton.classList.remove("hide");
            this.teamButton.classList.remove("hide");
            this.collectionButton.classList.remove("hide");
            this.instagramButton.classList.remove("hide");
            this.showingMenu = true;
        } else {
            this.menuContainer.classList.add("nopointer");
            this.whatButton.classList.remove("visible");
            this.faqButton.classList.remove("visible");
            this.teamButton.classList.remove("visible");
            this.collectionButton.classList.remove("visible");
            this.instagramButton.classList.remove("visible");
            this.whatButton.classList.add("hide");
            this.faqButton.classList.add("hide");
            this.teamButton.classList.add("hide");
            this.collectionButton.classList.add("hide");
            this.instagramButton.classList.add("hide");
            this.showingMenu = false;
        }
    }

    showDevButtons(show) {
        if (show) {
            // Next Buttons
            this.nextDevContainer.classList.remove("nopointer");
            this.nextDevButton.classList.add("visible");
            this.previousDevButton.classList.add("visible");
            this.nextDevButton.classList.remove("hide");
            this.previousDevButton.classList.remove("hide");

            // Add vertical offset to commentary for mobile.
            if (this.experience.isMobile) {
                this.experience.devCommentary.commentaryContainer.classList.add(
                    "vertical-offset"
                );
            }

            this.showingDev = true;
        } else {
            // Next Buttons
            this.nextDevContainer.classList.add("nopointer");
            this.nextDevButton.classList.remove("visible");
            this.previousDevButton.classList.remove("visible");
            this.nextDevButton.classList.add("hide");
            this.previousDevButton.classList.add("hide");

            // Remove vertical offset to commentary for mobile.
            if (this.experience.isMobile) {
                this.experience.devCommentary.commentaryContainer.classList.remove(
                    "vertical-offset"
                );
            }

            this.showingDev = false;

            this.showDevBio(false, 0);
            this.showDevBio(false, 1);
            this.showDevBio(false, 2);

            this.playingQuote = false;
            this.showSpeechBubble(false);
        }
    }

    showDevBio(show, index) {
        this.clearQuoteTimeouts();
        this.showSpeechBubble(false);

        if (show) {
            // Play commentary.
            this.experience.playCommentary(COMMENTARY_TEAM[index]);

            if (index == 0) {
                this.devBio1.classList.add("visible");
                this.devBio1.classList.remove("hide");
                this.showDevBio(false, 1);
                this.showDevBio(false, 2);
            } else if (index == 1) {
                this.devBio2.classList.add("visible");
                this.devBio2.classList.remove("hide");
                this.showDevBio(false, 0);
                this.showDevBio(false, 2);
            } else if (index == 2) {
                this.devBio3.classList.add("visible");
                this.devBio3.classList.remove("hide");
                this.showDevBio(false, 0);
                this.showDevBio(false, 1);
            }

            let timeoutID = window.setTimeout(() => {
                this.playQuote(index, this.quoteIndex[index]);
            }, FIRST_QUOTE_DELAY);
            this.addQuoteTimeout(timeoutID);
        } else {
            // Hide Dev Bio
            if (index == 0) {
                this.devBio1.classList.remove("visible");
                this.devBio1.classList.add("hide");
            } else if (index == 1) {
                this.devBio2.classList.remove("visible");
                this.devBio2.classList.add("hide");
            } else if (index == 2) {
                this.devBio3.classList.remove("visible");
                this.devBio3.classList.add("hide");
            }
        }
    }

    playQuote(index) {
        if (this.showingDev && this.experience.whoIndex == index) {
            this.currentQuote = "";
            this.speechBubble.innerHTML = "";
            this.showSpeechBubble(true);

            let timeoutID = window.setTimeout(() => {
                this.playingQuote = true;
            }, START_QUOTE_DELAY);
            this.addQuoteTimeout(timeoutID);
        }
    }

    playAnswer(answer, div) {
        // Do nothing if already selected.
        if (this.selectedDiv == div) {
            return;
        }

        if (this.selectedDiv) {
            this.selectedDiv.classList.remove("selected");
        }

        this.currentAnswer = answer;
        this.newAnswer = "";
        this.faqAnswer.innerHTML = "";
        this.showFAQAnswer(true);

        this.selectedDiv = div;
        div.classList.add("selected");

        this.playingAnswer = true;
    }

    updateAnswer(lettersToAdd) {
        if (this.newAnswer.length < this.currentAnswer.length) {
            let newChar = this.currentAnswer.substring(
                this.newAnswer.length,
                this.newAnswer.length + lettersToAdd
            );
            // Add next character too if space.
            if (newChar == " ") {
                newChar += this.currentAnswer.substring(
                    this.newAnswer.length + 1,
                    this.newAnswer.length + lettersToAdd + 1
                );
            }

            // Add delay if new line.
            if (newChar.includes("|")) {
                this.elapsed -= 150;
            }
            this.newAnswer += newChar;
            this.faqAnswer.innerHTML = this.newAnswer.replaceAll("|", "<br/>");
        } else {
            this.playingAnswer = false;
            this.experience.playCommentary(this.answerCommentary);
        }
    }

    updateQuote(lettersToAdd) {
        if (this.showingDev && this.playingQuote) {
            const index1 = this.experience.whoIndex;
            const index2 = this.quoteIndex[index1];
            if (this.currentQuote.length < devQuotes[index1][index2].length) {
                let newChar = devQuotes[index1][index2].substring(
                    this.currentQuote.length,
                    this.currentQuote.length + lettersToAdd
                );
                // Add next character too if space.
                if (newChar == " ") {
                    newChar += devQuotes[index1][index2].substring(
                        this.currentQuote.length + 1,
                        this.currentQuote.length + lettersToAdd + 1
                    );
                }

                this.currentQuote += newChar;
                this.speechBubble.innerHTML = this.currentQuote.replaceAll(
                    "|",
                    "<br/>"
                );
            }

            if (this.currentQuote.length == devQuotes[index1][index2].length) {
                this.playingQuote = false;
                this.quoteIndex[index1] =
                    (this.quoteIndex[index1] + 1) % devQuotes[index1].length;

                if (this.quoteIndex[index1] == 0) {
                    // Hide speech bubble and reset quotes after long delay.
                    let timeoutID = window.setTimeout(() => {
                        this.showSpeechBubble(false);
                        window.setTimeout(() => {
                            this.lastTimeoutID = this.playQuote(
                                index1,
                                this.quoteIndex[index1]
                            );
                        }, RESET_QUOTE_DELAY);
                    }, NEXT_QUOTE_DELAY);
                    this.addQuoteTimeout(timeoutID);
                } else {
                    // Play next quote.
                    let timeoutID = window.setTimeout(() => {
                        this.lastTimeoutID = this.playQuote(
                            index1,
                            this.quoteIndex[index1]
                        );
                    }, NEXT_QUOTE_DELAY);
                    this.addQuoteTimeout(timeoutID);
                }
            }
        }
    }

    showBack(show) {
        if (show) {
            this.backButton.classList.add("visible");
            this.backButton.classList.remove("hide");
            this.showingBack = true;
        } else {
            this.backButton.classList.remove("visible");
            this.backButton.classList.add("hide");
            this.showingBack = false;
        }
    }

    showSpeechBubble(show) {
        if (show) {
            this.speechBubble.classList.add("visible");
            this.speechBubble.classList.remove("hide");
        } else {
            this.speechBubble.classList.remove("visible");
            this.speechBubble.classList.add("hide");
        }
    }

    showHiddenDevPhoto(show, index) {
        if (show) {
            this.hiddenDevPhotos[index].classList.add("visible");
        } else {
            this.hiddenDevPhotos[index].classList.remove("visible");
        }
    }

    showFAQ(show) {
        if (show) {
            this.faqContainer.classList.add("visible");
            this.faqContainer.classList.remove("hide");
        } else {
            this.faqContainer.classList.remove("visible");
            this.faqContainer.classList.add("hide");
        }

        // Hide answer
        this.showFAQAnswer(false);
    }

    showFAQAnswer(show) {
        if (show) {
            this.faqAnswer.classList.add("visible");
            this.faqAnswer.classList.remove("hide");
        } else {
            this.faqAnswer.classList.remove("visible");
            this.faqAnswer.classList.add("hide");
            this.experience.world.emotion.showEmotion(-1);
            this.elapsed = 0;

            // Remove selected div.
            if (this.selectedDiv) {
                this.selectedDiv.classList.remove("selected");
                this.selectedDiv = null;
            }
        }
    }

    setButtons() {
        this.menuButtons = new THREE.Group();

        // Menu Container
        this.menuContainer = document.querySelector("#menu");

        // What Button
        this.whatButton = document.querySelector("#whatButton");
        this.whatButton.addEventListener("click", () => {
            this.experience.changeScene(SCENE_WHAT);
        });

        // FAQ Button
        this.faqButton = document.querySelector("#faqButton");
        this.faqButton.addEventListener("click", () => {
            this.experience.changeScene(SCENE_FAQ);
        });

        // Team Button
        this.teamButton = document.querySelector("#teamButton");
        this.teamButton.addEventListener("click", () => {
            this.experience.changeScene(SCENE_WHO);
        });

        // Collection Button
        this.collectionButton = document.querySelector("#collectionButton");
        this.collectionButton.addEventListener("click", () => {
            window.open("https://app.wildwesters.io/collection");
        });

        this.instagramButton = document.querySelector("#instagramButton");

        // Back Button
        this.backButton = document.querySelector("#backButton");
        this.backButton.addEventListener("click", () => {
            this.experience.goBack();
        });
        // Keyboard escape - back button.
        document.addEventListener("keyup", (event) => {
            if (event.key === "Escape") {
                if (this.showingBack) {
                    this.experience.goBack();
                }
            }
        });

        // Next Dev Container
        this.nextDevContainer = document.querySelector("#nextDev");

        // Next Dev Button
        this.nextDevButton = document.querySelector("#nextDevButton");
        this.nextDevButton.addEventListener("click", () => {
            this.experience.nextTeamMember();
        });

        // Previous Dev Button
        this.previousDevButton = document.querySelector("#previousDevButton");
        this.previousDevButton.addEventListener("click", () => {
            this.experience.previousTeamMember();
        });

        // DevBio 1
        this.devBio1 = document.querySelector("#devBio1");
        this.devBio2 = document.querySelector("#devBio2");
        this.devBio3 = document.querySelector("#devBio3");

        const hiddenDevPhoto1 = document.querySelector("#hiddenDevPhoto1");
        const hiddenDevPhoto2 = document.querySelector("#hiddenDevPhoto2");
        const hiddenDevPhoto3 = document.querySelector("#hiddenDevPhoto3");
        this.hiddenDevPhotos = [
            hiddenDevPhoto1,
            hiddenDevPhoto2,
            hiddenDevPhoto3,
        ];
        this.devPhotoSecret = [false, false, false];

        for (let i = 0; i < this.hiddenDevPhotos.length; i++) {
            this.showHiddenDevPhoto(false, i);
            if (this.experience.isMobile) {
                // Toggle hidden message on click for mobile..
                this.hiddenDevPhotos[i].addEventListener("click", () => {
                    this.devPhotoSecret[i] = !this.devPhotoSecret[i];
                    this.showHiddenDevPhoto(this.devPhotoSecret[i], i);
                });
            } else {
                // Show/hide secret image on mouse enter/exit for PC.
                this.hiddenDevPhotos[i].addEventListener("mouseenter", () => {
                    this.showHiddenDevPhoto(true, i);
                });
                this.hiddenDevPhotos[i].addEventListener("mouseleave", () => {
                    this.showHiddenDevPhoto(false, i);
                });
            }
        }

        this.speechBubble = document.querySelector("#speechBubble");
        this.speechBubble.addEventListener("click", () => {
            this.playingQuote = false;
            this.showSpeechBubble(false);
        });

        // FAQ Questions
        this.faqContainer = document.querySelector("#faqContainer");
        this.faqQuestions = document.querySelector("#faqQuestions");
        // Add scrollable indicator
        this.faqShadowBot = document.querySelector("#faqShadowBot");
        this.faqShadowTop = document.querySelector("#faqShadowTop");

        this.addQuestions();
        this.faqAnswer = document.querySelector("#faqAnswer");
        this.faqAnswer.addEventListener("click", () => {
            this.showFAQAnswer(false);
        });
    }

    addQuestions() {
        FAQ.forEach((element) => {
            const faqQuestion = document.createElement("div");
            faqQuestion.classList.add("faq-question");
            faqQuestion.innerHTML = element[0];
            faqQuestion.addEventListener("click", () => {
                if (this.experience.activeScene == SCENE_FAQ) {
                    this.playAnswer(element[1], faqQuestion);
                    this.experience.world.emotion.showEmotion(element[2]);
                    this.answerCommentary = element[3];
                }
            });
            this.faqQuestions.appendChild(faqQuestion);
        });
    }

    update() {
        if (this.experience.what) {
            this.experience.changeScene(SCENE_WHAT);
            this.experience.what = false;
        }

        if (this.experience.activeScene == SCENE_HOME) {
            if (
                this.experience.scroll.scrollProgress == 1 &&
                !this.experience.inTransition
            ) {
                if (!this.showingMenu) {
                    this.showMenu(true);
                }
            } else {
                if (this.showingMenu) {
                    this.showMenu(false);
                }
            }
        }

        if (this.experience.activeScene == SCENE_WHAT) {
            if (!this.showingPageController) {
                this.experience.productPageController.showPageController(true);
                this.showingPageController = true;
            }
        } else if (this.showingPageController) {
            this.experience.productPageController.showPageController(false);
            this.showingPageController = false;
        }

        if (this.experience.activeScene == SCENE_FAQ) {
            if (this.playingAnswer) {
                this.elapsed += this.time.delta;
                if (this.elapsed > SPEECH_TICK) {
                    const lettersToAdd = Math.floor(this.elapsed / SPEECH_TICK);
                    this.elapsed = this.elapsed - SPEECH_TICK * lettersToAdd;
                    this.updateAnswer(lettersToAdd);
                }
            }

            // Update scroll indicator opacity
            const faqQuestionsScrollPercentage =
                this.faqQuestions.scrollTop /
                (this.faqQuestions.scrollHeight -
                    this.faqQuestions.clientHeight);
            this.faqShadowBot.style.opacity = 1 - faqQuestionsScrollPercentage;
            this.faqShadowTop.style.opacity = faqQuestionsScrollPercentage;
        }

        if (this.experience.activeScene == SCENE_WHO) {
            if (this.playingQuote) {
                this.elapsed += this.time.delta;
                if (this.elapsed > SPEECH_TICK) {
                    const lettersToAdd = Math.floor(this.elapsed / SPEECH_TICK);
                    this.elapsed = this.elapsed - SPEECH_TICK * lettersToAdd;
                    this.updateQuote(lettersToAdd);
                }
            }

            if (!this.showingDev) {
                this.showDevButtons(true);
            }
        }
    }
}
