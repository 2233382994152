import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";
import { SpriteAnimator } from "../Utils/SpriteAnimator.js";

const positions = [
    [0, 5],
    [17.5, 24],
    [3, 35],
    [-13.5, 6.5],
    [-3.8, 22],
    [-5.8, 44],
    [16.5, 5.5],
];
let currentIndex = 0;

// Shared
let spriteResources = [];
let modelResources = [];
let invisMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.001,
});

// Bird
const birdFrames = 2;

export default class Structure {
    constructor(type) {
        // Prevent adding more structures than possible preset positions.
        if (currentIndex >= positions.length) {
            return;
        }

        this.type = type;

        this.experience = new Experience();
        this.scene = this.experience.scene;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                this.resources.items.Structure1,
                this.resources.items.Structure2,
                this.resources.items.Structure3,
                this.resources.items.Structure4,
                this.resources.items.Structure5,
                this.resources.items.Structure6,
                this.resources.items.Structure7,
            ];
            spriteResources.forEach((item) => {
                item.magFilter = THREE.NearestFilter;
                item.minFilter = THREE.NearestFilter;
            });
        }
        if (modelResources.length == 0) {
            modelResources = [
                this.resources.items.Structure1Model,
                this.resources.items.Structure2Model,
                this.resources.items.Structure3Model,
                this.resources.items.Structure4Model,
                this.resources.items.Structure5Model,
                this.resources.items.Structure6Model,
                this.resources.items.Structure7Model,
            ];
        }

        this.modelResource = modelResources[this.type].scene.clone();

        this.group = new THREE.Group();
        this.setSprite();
        this.setShadow();
    }

    setShadow() {
        this.shadowModel = this.modelResource;
        this.shadowModel.rotation.x = Math.PI * 0.5;
        this.shadowModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = invisMaterial;
            }
        });

        this.shadowModel.scale.set(44, 44, 44);
        this.shadowModel.position.y = 3.95;
        this.shadowModel.position.x = -3.97;

        this.shadowModel.children[0].castShadow = true;
        this.shadowModel.children[0].layers.set(
            this.experience.world.HOME_LAYER
        );

        this.group.add(this.shadowModel);
    }

    // Just to update the bird.
    updateShadow(currentFrame) {
        this.birdModel.children[
            (currentFrame + birdFrames - 1) % birdFrames
        ].castShadow = false;
        this.birdModel.children[currentFrame].castShadow = true;

        this.birdModel.children.forEach((obj) => {
            obj.layers.set(this.experience.world.HOME_LAYER);
        });
    }

    // Also just for the bird.
    setBirdShadow() {
        this.birdModel = this.resources.items.BirdShadow.scene.clone();
        this.birdModel.rotation.x = Math.PI * 0.5;
        this.birdModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = invisMaterial;
            }
        });
        this.birdModel.scale.set(44, 44, 44);
        this.birdModel.position.y = 3.95;
        this.birdModel.position.x = -3.97;

        this.updateShadow(this.spriteAnimator.currentTile);

        this.group.add(this.birdModel);
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: spriteResources[this.type] })
        );
        this.sprite.layers.set(this.experience.world.HOME_LAYER);

        this.group.add(this.sprite);
        this.sprite.scale.set(8, 8, 8);
        this.group.position.set(
            positions[currentIndex][0],
            3.15,
            positions[currentIndex][1]
        );
        currentIndex++;

        // Add the bird.
        if (this.type == 4) {
            this.birdSprite = this.resources.items.Bird.clone();

            this.spriteAnimator = new SpriteAnimator(
                this.birdSprite,
                2,
                1,
                this
            );
            this.spriteAnimator.sprite.scale.set(8, 8, 8);
            this.spriteAnimator.loop([0, 1], 1000);
            this.spriteAnimator.sprite.layers.set(
                this.experience.world.HOME_LAYER
            );

            this.group.add(this.spriteAnimator.sprite);
            this.setBirdShadow();
        }

        this.scene.add(this.group);
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.sprite.material.color.set(newColor);

        // Update the bird.
        if (this.type == 4) {
            this.spriteAnimator.update();
            this.spriteAnimator.sprite.material.color.set(newColor);
        }
    }
}
