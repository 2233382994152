import * as THREE from "three";
import Experience, { SCENE_HOME } from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import TWEEN from "@tweenjs/tween.js";

const TEST_POSITION = [];

const CAMERA_POSITION = [
    new THREE.Vector3(0, 3.5, 6),
    new THREE.Vector3(3.4, 1.5, 1.2),
    new THREE.Vector3(0, 0.4, -4.88),
    new THREE.Vector3(-2.4, 0.8, -2),
    new THREE.Vector3(1.25, 3, 12.24),
];
const TARGET_POSITION = [
    new THREE.Vector3(0, 1.8, -0.3),
    new THREE.Vector3(0, 1.3, -0.2),
    new THREE.Vector3(0, 2, -0.5),
    new THREE.Vector3(0.2, 1, -0.1),
    new THREE.Vector3(1.25, 1.8, -0.3),
];

const PAGE4_CAMERA_POSITION = [
    new THREE.Vector3(0, 4.5, -1.4),
    new THREE.Vector3(0, 0.5, 4.5),
];
const PAGE4_TARGET_POSITION = [
    new THREE.Vector3(0, 0.1, -1.5),
    new THREE.Vector3(0, 0.8, -1.5),
];

const ENABLE_CONTROLS = [true, true, false, false, true];
const TARGET_ROTATE = [true, true, false, false, true];
const DEFAULT_MAX_POLAR_ANGLE = Math.PI / 2.01;
const PAGE_MAX_POLAR_ANGLE = [
    DEFAULT_MAX_POLAR_ANGLE,
    DEFAULT_MAX_POLAR_ANGLE,
    2.2,
    Math.PI / 1.68,
    DEFAULT_MAX_POLAR_ANGLE,
];
const ROTATE_SPEED = [1, 1, 1, 1, 0.5];

const PREVIEW_CAMERA_RETURN_POSITION = new THREE.Vector3(0, 2, 3.43);
const PREVIEW_CAMERA_POSITION = new THREE.Vector3(0, 2, 2.5);
const PREVIEW_TARGET_POSITION = new THREE.Vector3(0, 1.1, -0.2);

export default class CameraTwo {
    constructor() {
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene2;
        this.canvas = this.experience.canvas;
        this.debug = this.experience.debug;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;

        // Just for debugging.
        this.lastPosition = new THREE.Vector3();

        this.currentTargetIndex = 0;

        this.setInstance();
        this.setOrbitControls();

        if (this.debug.active) {
            this.createTestPositionMarkers();
        }
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(
            50,
            this.sizes.width / this.sizes.height,
            0.1,
            100
        );
    }

    createTestPositionMarkers() {
        TEST_POSITION.forEach((target) => {
            const helper = new THREE.Mesh(
                new THREE.SphereGeometry(0.1, 32, 32),
                new THREE.MeshBasicMaterial({
                    color: 0x11a926,
                    opacity: 0.25,
                    transparent: true,
                })
            );
            helper.position.copy(target);
            this.scene.add(helper);
        });
    }

    page4Position(index) {
        this.tweenCamera(
            PAGE4_CAMERA_POSITION[index],
            PAGE4_TARGET_POSITION[index],
            2000
        );
    }

    setTarget(index, tween, duration) {
        if (tween) {
            this.tweenCamera(
                CAMERA_POSITION[index],
                TARGET_POSITION[index],
                // 1000
                duration ? duration : 1000
            );
        } else {
            this.instance.position.copy(CAMERA_POSITION[index]);
            this.controls.target.copy(TARGET_POSITION[index]);
        }
        this.controls.autoRotate = TARGET_ROTATE[index];
        this.controls.maxPolarAngle = PAGE_MAX_POLAR_ANGLE[index];
        this.controls.enabled = ENABLE_CONTROLS[index];

        this.controls.autoRotateSpeed = ROTATE_SPEED[index];
    }

    previewMode(enter) {
        if (enter) {
            this.tweenCamera(
                PREVIEW_CAMERA_POSITION,
                PREVIEW_TARGET_POSITION,
                1000
            );
            this.controls.autoRotate = false;
        } else {
            this.tweenCamera(
                PREVIEW_CAMERA_RETURN_POSITION,
                TARGET_POSITION[1],
                1000
            );
            this.controls.autoRotate = true;
        }
    }

    setOrbitControls() {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.maxPolarAngle = PAGE_MAX_POLAR_ANGLE[0];
        this.controls.enableDamping = true;
        this.controls.enablePan = false;
        this.controls.autoRotateSpeed = 1;
        this.controls.enableZoom = false;
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    tweenCamera(cameraPosition, targetPosition, duration) {
        if (this.lastTargetTween) this.lastTargetTween.stop();
        if (this.lastCameraTween) this.lastCameraTween.stop();

        this.lastTargetTween = new TWEEN.Tween(this.controls.target)
            .to(
                {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        this.lastCameraTween = new TWEEN.Tween(this.instance.position)
            .to(
                {
                    x: cameraPosition.x,
                    y: cameraPosition.y,
                    z: cameraPosition.z,
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }

    logPosition() {
        // Log position if changed.
        if (
            this.lastPosition.x != this.instance.position.x &&
            this.lastPosition.y != this.instance.position.y &&
            this.lastPosition.z != this.instance.position.z
        ) {
            this.lastPosition.set(
                this.instance.position.x,
                this.instance.position.y,
                this.instance.position.z
            );
            console.log(this.lastPosition);
        }
    }

    update() {
        // this.logPosition();

        this.controls.update();
    }
}
