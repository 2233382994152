import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

export default class ScrollDown {
    constructor() {
        this.experience = new Experience();
        this.camera = this.experience.camera.instance;
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;

        this.fadeOutStart = 0.05;
        this.startTime = 0; // default value for "start time" the exact moment text starts fading in.

        // Resource
        this.resources = this.experience.resources;
        if (this.experience.isMobile) {
            this.resource = this.resources.items.SwipeUp;
        } else {
            this.resource = this.resources.items.ScrollDown;
        }
        this.resource.magFilter = THREE.NearestFilter;
        this.resource.minFilter = THREE.NearestFilter;

        this.setSprite();
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: this.resource })
        );
        this.sprite.layers.set(this.experience.world.UI_LAYER);

        this.sprite.position.y = -0.66;
        this.sprite.position.z = -2.375;

        if (this.experience.isMobile) {
            const scale = 0.07;
            this.sprite.scale.set(6 * scale, scale, scale);
        } else {
            const scale = 0.1;
            this.sprite.scale.set(6 * scale, scale, scale);
        }

        this.sprite.material.transparent = true;
        this.sprite.material.opacity = 0;

        this.camera.add(this.sprite);
    }

    update() {
        // Update opacity to scroll value
        // if (this.time.elapsed > 0) {
        if (this.time.elapsed > 8000) {
            if (this.startTime == 0) {
                this.startTime = this.time.elapsed;
            }
            let opacityValue = 0;
            if (this.scroll.scrollProgress < this.fadeOutStart) {
                opacityValue = Math.sin(
                    (this.time.elapsed - this.startTime) * 0.001
                );
            }
            this.sprite.material.opacity +=
                (opacityValue - this.sprite.material.opacity) *
                this.time.delta *
                0.005;
        }
    }
}
