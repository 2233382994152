import * as THREE from "three";

import Experience, { SCENE_HOME, SCENE_WHAT } from "../Experience.js";
import Environment from "./Environment.js";
import Floor from "./Floor.js";
import Product from "./Product.js";
import Overlay from "../World/Overlay.js";

export default class ProductDemo {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene2;
        this.resources = this.experience.resources;
        this.time = this.experience.time;

        // Layers
        this.MAIN_LAYER = 1;
        this.UI_LAYER = 9;
        this.OVERLAY_LAYER = 10;

        this.overlay = new Overlay(this.scene, this.OVERLAY_LAYER);

        // Wait for resources
        this.resources.on("ready", () => {
            this.environment = new Environment();
            this.floor = new Floor();

            this.product = new Product();

            this.UpdateObjects = [
                // Environment
                [this.environment],
                [this.product],
            ];
        });
    }

    update() {
        if (
            this.experience.activeScene == SCENE_WHAT &&
            this.time.delta < 1000
        ) {
            // Update items
            if (this.UpdateObjects) {
                this.UpdateObjects.forEach((array) =>
                    array.forEach((item) => {
                        item.update();
                    })
                );
            }
        }
    }
}
