import * as THREE from "three";
import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 76;

export default class Eiffel extends CompareObject {
    constructor() {
        super();

        this.model = this.resources.items.Eiffel.scene.clone();
        this.setModel();
        this.setPosition();
    }

    setModel() {
        this.meshes = [];
        this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                this.meshes.push(child);
                child.material = new THREE.MeshMatcapMaterial({
                    color: 0x333333,
                    transparent: true,
                    opacity: 0,
                });
            }
        });
    }

    setPosition() {
        this.model.position.set(7600, 0, -2250);
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }
}
