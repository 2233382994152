export default [
    // Title
    {
        name: "Title",
        type: "sprite",
        path: "sprites/Title.png",
    },

    // Floor
    {
        name: "sandColorTexture",
        type: "texture",
        path: "textures/sand/color.png",
    },
    {
        name: "sandNormalTexture",
        type: "texture",
        path: "textures/sand/normal.png",
    },
    {
        name: "sandAOTexture",
        type: "texture",
        path: "textures/sand/ao.png",
    },

    // Wild Westers
    {
        name: "eggDev",
        type: "sprite",
        path: "sprites/WildWesters/dev/0.png",
    },
    // Egg
    {
        name: "egg0",
        type: "sprite",
        path: "sprites/WildWesters/egg/0.png",
    },
    {
        name: "egg1",
        type: "sprite",
        path: "sprites/WildWesters/egg/1.png",
    },
    {
        name: "egg2",
        type: "sprite",
        path: "sprites/WildWesters/egg/2.png",
    },
    {
        name: "egg3",
        type: "sprite",
        path: "sprites/WildWesters/egg/3.png",
    },
    {
        name: "egg4",
        type: "sprite",
        path: "sprites/WildWesters/egg/4.png",
    },
    {
        name: "egg5",
        type: "sprite",
        path: "sprites/WildWesters/egg/5.png",
    },
    {
        name: "egg6",
        type: "sprite",
        path: "sprites/WildWesters/egg/6.png",
    },
    {
        name: "egg7",
        type: "sprite",
        path: "sprites/WildWesters/egg/7.png",
    },
    {
        name: "egg8",
        type: "sprite",
        path: "sprites/WildWesters/egg/8.png",
    },
    {
        name: "egg9",
        type: "sprite",
        path: "sprites/WildWesters/egg/9.png",
    },
    {
        name: "egg10",
        type: "sprite",
        path: "sprites/WildWesters/egg/10.png",
    },
    {
        name: "egg11",
        type: "sprite",
        path: "sprites/WildWesters/egg/11.png",
    },
    {
        name: "egg12",
        type: "sprite",
        path: "sprites/WildWesters/egg/12.png",
    },
    {
        name: "egg13",
        type: "sprite",
        path: "sprites/WildWesters/egg/13.png",
    },
    {
        name: "egg14",
        type: "sprite",
        path: "sprites/WildWesters/egg/14.png",
    },
    {
        name: "egg15",
        type: "sprite",
        path: "sprites/WildWesters/egg/15.png",
    },
    // Donut
    {
        name: "donut0",
        type: "sprite",
        path: "sprites/WildWesters/donut/0.png",
    },
    {
        name: "donut1",
        type: "sprite",
        path: "sprites/WildWesters/donut/1.png",
    },
    {
        name: "donut2",
        type: "sprite",
        path: "sprites/WildWesters/donut/2.png",
    },
    {
        name: "donut3",
        type: "sprite",
        path: "sprites/WildWesters/donut/3.png",
    },
    {
        name: "donut4",
        type: "sprite",
        path: "sprites/WildWesters/donut/4.png",
    },
    {
        name: "donut5",
        type: "sprite",
        path: "sprites/WildWesters/donut/5.png",
    },
    {
        name: "donut6",
        type: "sprite",
        path: "sprites/WildWesters/donut/6.png",
    },
    {
        name: "donut7",
        type: "sprite",
        path: "sprites/WildWesters/donut/7.png",
    },
    {
        name: "donut8",
        type: "sprite",
        path: "sprites/WildWesters/donut/8.png",
    },
    {
        name: "donut9",
        type: "sprite",
        path: "sprites/WildWesters/donut/9.png",
    },
    {
        name: "donut10",
        type: "sprite",
        path: "sprites/WildWesters/donut/10.png",
    },
    {
        name: "donut11",
        type: "sprite",
        path: "sprites/WildWesters/donut/11.png",
    },
    {
        name: "donut12",
        type: "sprite",
        path: "sprites/WildWesters/donut/12.png",
    },
    {
        name: "donut13",
        type: "sprite",
        path: "sprites/WildWesters/donut/13.png",
    },
    {
        name: "donut14",
        type: "sprite",
        path: "sprites/WildWesters/donut/14.png",
    },
    {
        name: "donut15",
        type: "sprite",
        path: "sprites/WildWesters/donut/15.png",
    },
    // Tomato
    {
        name: "tomato0",
        type: "sprite",
        path: "sprites/WildWesters/tomato/0.png",
    },
    {
        name: "tomato1",
        type: "sprite",
        path: "sprites/WildWesters/tomato/1.png",
    },
    {
        name: "tomato2",
        type: "sprite",
        path: "sprites/WildWesters/tomato/2.png",
    },
    {
        name: "tomato3",
        type: "sprite",
        path: "sprites/WildWesters/tomato/3.png",
    },
    {
        name: "tomato4",
        type: "sprite",
        path: "sprites/WildWesters/tomato/4.png",
    },
    {
        name: "tomato5",
        type: "sprite",
        path: "sprites/WildWesters/tomato/5.png",
    },
    {
        name: "tomato6",
        type: "sprite",
        path: "sprites/WildWesters/tomato/6.png",
    },
    {
        name: "tomato7",
        type: "sprite",
        path: "sprites/WildWesters/tomato/7.png",
    },
    {
        name: "tomato8",
        type: "sprite",
        path: "sprites/WildWesters/tomato/8.png",
    },
    {
        name: "tomato9",
        type: "sprite",
        path: "sprites/WildWesters/tomato/9.png",
    },
    {
        name: "tomato10",
        type: "sprite",
        path: "sprites/WildWesters/tomato/10.png",
    },
    {
        name: "tomato11",
        type: "sprite",
        path: "sprites/WildWesters/tomato/11.png",
    },
    {
        name: "tomato12",
        type: "sprite",
        path: "sprites/WildWesters/tomato/12.png",
    },
    {
        name: "tomato13",
        type: "sprite",
        path: "sprites/WildWesters/tomato/13.png",
    },
    {
        name: "tomato14",
        type: "sprite",
        path: "sprites/WildWesters/tomato/14.png",
    },
    {
        name: "tomato15",
        type: "sprite",
        path: "sprites/WildWesters/tomato/15.png",
    },
    // Pickle
    {
        name: "pickle0",
        type: "sprite",
        path: "sprites/WildWesters/pickle/0.png",
    },
    {
        name: "pickle1",
        type: "sprite",
        path: "sprites/WildWesters/pickle/1.png",
    },
    {
        name: "pickle2",
        type: "sprite",
        path: "sprites/WildWesters/pickle/2.png",
    },
    {
        name: "pickle3",
        type: "sprite",
        path: "sprites/WildWesters/pickle/3.png",
    },
    {
        name: "pickle4",
        type: "sprite",
        path: "sprites/WildWesters/pickle/4.png",
    },
    {
        name: "pickle5",
        type: "sprite",
        path: "sprites/WildWesters/pickle/5.png",
    },
    {
        name: "pickle6",
        type: "sprite",
        path: "sprites/WildWesters/pickle/6.png",
    },
    {
        name: "pickle7",
        type: "sprite",
        path: "sprites/WildWesters/pickle/7.png",
    },
    {
        name: "pickle8",
        type: "sprite",
        path: "sprites/WildWesters/pickle/8.png",
    },
    {
        name: "pickle9",
        type: "sprite",
        path: "sprites/WildWesters/pickle/9.png",
    },
    {
        name: "pickle10",
        type: "sprite",
        path: "sprites/WildWesters/pickle/10.png",
    },
    {
        name: "pickle11",
        type: "sprite",
        path: "sprites/WildWesters/pickle/11.png",
    },
    {
        name: "pickle12",
        type: "sprite",
        path: "sprites/WildWesters/pickle/12.png",
    },
    {
        name: "pickle13",
        type: "sprite",
        path: "sprites/WildWesters/pickle/13.png",
    },
    {
        name: "pickle14",
        type: "sprite",
        path: "sprites/WildWesters/pickle/14.png",
    },
    {
        name: "pickle15",
        type: "sprite",
        path: "sprites/WildWesters/pickle/15.png",
    },
    // Toast
    {
        name: "toast0",
        type: "sprite",
        path: "sprites/WildWesters/toast/0.png",
    },
    {
        name: "toast1",
        type: "sprite",
        path: "sprites/WildWesters/toast/1.png",
    },
    {
        name: "toast2",
        type: "sprite",
        path: "sprites/WildWesters/toast/2.png",
    },
    {
        name: "toast3",
        type: "sprite",
        path: "sprites/WildWesters/toast/3.png",
    },
    {
        name: "toast4",
        type: "sprite",
        path: "sprites/WildWesters/toast/4.png",
    },
    {
        name: "toast5",
        type: "sprite",
        path: "sprites/WildWesters/toast/5.png",
    },
    {
        name: "toast6",
        type: "sprite",
        path: "sprites/WildWesters/toast/6.png",
    },
    {
        name: "toast7",
        type: "sprite",
        path: "sprites/WildWesters/toast/7.png",
    },
    {
        name: "toast8",
        type: "sprite",
        path: "sprites/WildWesters/toast/8.png",
    },
    {
        name: "toast9",
        type: "sprite",
        path: "sprites/WildWesters/toast/9.png",
    },
    {
        name: "toast10",
        type: "sprite",
        path: "sprites/WildWesters/toast/10.png",
    },
    {
        name: "toast11",
        type: "sprite",
        path: "sprites/WildWesters/toast/11.png",
    },
    {
        name: "toast12",
        type: "sprite",
        path: "sprites/WildWesters/toast/12.png",
    },
    {
        name: "toast13",
        type: "sprite",
        path: "sprites/WildWesters/toast/13.png",
    },
    {
        name: "toast14",
        type: "sprite",
        path: "sprites/WildWesters/toast/14.png",
    },
    {
        name: "toast15",
        type: "sprite",
        path: "sprites/WildWesters/toast/15.png",
    },
    // Cheese
    {
        name: "cheese0",
        type: "sprite",
        path: "sprites/WildWesters/cheese/0.png",
    },
    {
        name: "cheese1",
        type: "sprite",
        path: "sprites/WildWesters/cheese/1.png",
    },
    {
        name: "cheese2",
        type: "sprite",
        path: "sprites/WildWesters/cheese/2.png",
    },
    {
        name: "cheese3",
        type: "sprite",
        path: "sprites/WildWesters/cheese/3.png",
    },
    {
        name: "cheese4",
        type: "sprite",
        path: "sprites/WildWesters/cheese/4.png",
    },
    {
        name: "cheese5",
        type: "sprite",
        path: "sprites/WildWesters/cheese/5.png",
    },
    {
        name: "cheese6",
        type: "sprite",
        path: "sprites/WildWesters/cheese/6.png",
    },
    {
        name: "cheese7",
        type: "sprite",
        path: "sprites/WildWesters/cheese/7.png",
    },
    {
        name: "cheese8",
        type: "sprite",
        path: "sprites/WildWesters/cheese/8.png",
    },
    {
        name: "cheese9",
        type: "sprite",
        path: "sprites/WildWesters/cheese/9.png",
    },
    {
        name: "cheese10",
        type: "sprite",
        path: "sprites/WildWesters/cheese/10.png",
    },
    {
        name: "cheese11",
        type: "sprite",
        path: "sprites/WildWesters/cheese/11.png",
    },
    {
        name: "cheese12",
        type: "sprite",
        path: "sprites/WildWesters/cheese/12.png",
    },
    {
        name: "cheese13",
        type: "sprite",
        path: "sprites/WildWesters/cheese/13.png",
    },
    {
        name: "cheese14",
        type: "sprite",
        path: "sprites/WildWesters/cheese/14.png",
    },
    {
        name: "cheese15",
        type: "sprite",
        path: "sprites/WildWesters/cheese/15.png",
    },

    // Models
    {
        name: "devEggShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/devEgg.gltf",
    },
    {
        name: "EggShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Egg.gltf",
    },
    {
        name: "DonutShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Donut.gltf",
    },
    {
        name: "TomatoShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Tomato.gltf",
    },
    {
        name: "PickleShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Pickle.gltf",
    },
    {
        name: "ToastShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Toast.gltf",
    },
    {
        name: "CheeseShadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/Cheese.gltf",
    },

    // Foilage
    {
        name: "Bush1",
        type: "sprite",
        path: "sprites/Foilage/Bush1.png",
    },
    {
        name: "Bush1Model",
        type: "gltfModel",
        path: "models/Foilage/Bush1.gltf",
    },
    {
        name: "Cactus1",
        type: "sprite",
        path: "sprites/Foilage/Cactus1.png",
    },
    {
        name: "Cactus1Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus1.gltf",
    },
    {
        name: "Cactus2",
        type: "sprite",
        path: "sprites/Foilage/Cactus2.png",
    },
    {
        name: "Cactus2Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus2.gltf",
    },
    {
        name: "Cactus3",
        type: "sprite",
        path: "sprites/Foilage/Cactus3.png",
    },
    {
        name: "Cactus3Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus3.gltf",
    },
    {
        name: "Cactus4",
        type: "sprite",
        path: "sprites/Foilage/Cactus4.png",
    },
    {
        name: "Cactus4Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus4.gltf",
    },
    {
        name: "Fence1",
        type: "sprite",
        path: "sprites/Foilage/Fence1.png",
    },
    {
        name: "Fence1Model",
        type: "gltfModel",
        path: "models/Foilage/Fence1.gltf",
    },
    {
        name: "Rock1",
        type: "sprite",
        path: "sprites/Foilage/Rock1.png",
    },
    {
        name: "Rock1Model",
        type: "gltfModel",
        path: "models/Foilage/Rock1.gltf",
    },
    {
        name: "Rock2",
        type: "sprite",
        path: "sprites/Foilage/Rock2.png",
    },
    {
        name: "Rock2Model",
        type: "gltfModel",
        path: "models/Foilage/Rock2.gltf",
    },
    {
        name: "Rock3",
        type: "sprite",
        path: "sprites/Foilage/Rock3.png",
    },
    {
        name: "Rock3Model",
        type: "gltfModel",
        path: "models/Foilage/Rock3.gltf",
    },

    {
        name: "Sign1",
        type: "sprite",
        path: "sprites/Foilage/Sign1.png",
    },
    {
        name: "Sign1Model",
        type: "gltfModel",
        path: "models/Foilage/Sign1.gltf",
    },

    {
        name: "Cactus5",
        type: "sprite",
        path: "sprites/Foilage/Cactus5.png",
    },
    {
        name: "Cactus5Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus5.gltf",
    },
    {
        name: "Cactus6",
        type: "sprite",
        path: "sprites/Foilage/Cactus6.png",
    },
    {
        name: "Cactus6Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus6.gltf",
    },
    {
        name: "Cactus7",
        type: "sprite",
        path: "sprites/Foilage/Cactus7.png",
    },
    {
        name: "Cactus7Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus7.gltf",
    },
    {
        name: "Cactus8",
        type: "sprite",
        path: "sprites/Foilage/Cactus8.png",
    },
    {
        name: "Cactus8Model",
        type: "gltfModel",
        path: "models/Foilage/Cactus8.gltf",
    },

    {
        name: "Branch1",
        type: "sprite",
        path: "sprites/Foilage/Branch1.png",
    },
    {
        name: "Branch1Model",
        type: "gltfModel",
        path: "models/Foilage/Branch1.gltf",
    },
    {
        name: "Branch2",
        type: "sprite",
        path: "sprites/Foilage/Branch2.png",
    },
    {
        name: "Branch2Model",
        type: "gltfModel",
        path: "models/Foilage/Branch2.gltf",
    },
    {
        name: "Branch3",
        type: "sprite",
        path: "sprites/Foilage/Branch3.png",
    },
    {
        name: "Branch3Model",
        type: "gltfModel",
        path: "models/Foilage/Branch3.gltf",
    },
    {
        name: "Rock4",
        type: "sprite",
        path: "sprites/Foilage/Rock4.png",
    },
    {
        name: "Rock4Model",
        type: "gltfModel",
        path: "models/Foilage/Rock4.gltf",
    },
    {
        name: "Rock5",
        type: "sprite",
        path: "sprites/Foilage/Rock5.png",
    },
    {
        name: "Rock5Model",
        type: "gltfModel",
        path: "models/Foilage/Rock5.gltf",
    },
    {
        name: "Rock6",
        type: "sprite",
        path: "sprites/Foilage/Rock6.png",
    },
    {
        name: "Rock6Model",
        type: "gltfModel",
        path: "models/Foilage/Rock6.gltf",
    },

    {
        name: "Cowskull1",
        type: "sprite",
        path: "sprites/Foilage/Cowskull1.png",
    },
    {
        name: "Cowskull1Model",
        type: "gltfModel",
        path: "models/Foilage/Cowskull1.gltf",
    },

    // Structures
    {
        name: "Structure1",
        type: "sprite",
        path: "sprites/Structures/Structure1.png",
    },
    {
        name: "Structure1Model",
        type: "gltfModel",
        path: "models/Structures/Structure1.gltf",
    },
    {
        name: "Structure2",
        type: "sprite",
        path: "sprites/Structures/Structure2.png",
    },
    {
        name: "Structure2Model",
        type: "gltfModel",
        path: "models/Structures/Structure2.gltf",
    },
    {
        name: "Structure3",
        type: "sprite",
        path: "sprites/Structures/Structure3.png",
    },
    {
        name: "Structure3Model",
        type: "gltfModel",
        path: "models/Structures/Structure3.gltf",
    },
    {
        name: "Structure4",
        type: "sprite",
        path: "sprites/Structures/Structure4.png",
    },
    {
        name: "Structure4Model",
        type: "gltfModel",
        path: "models/Structures/Structure4.gltf",
    },
    {
        name: "Structure5",
        type: "sprite",
        path: "sprites/Structures/Structure5.png",
    },
    {
        name: "Structure5Model",
        type: "gltfModel",
        path: "models/Structures/Structure5.gltf",
    },
    {
        name: "Structure6",
        type: "sprite",
        path: "sprites/Structures/Structure6.png",
    },
    {
        name: "Structure6Model",
        type: "gltfModel",
        path: "models/Structures/Structure6.gltf",
    },
    {
        name: "Structure7",
        type: "sprite",
        path: "sprites/Structures/Structure7.png",
    },
    {
        name: "Structure7Model",
        type: "gltfModel",
        path: "models/Structures/Structure7.gltf",
    },

    //Mountains
    {
        name: "Mountain1",
        type: "sprite",
        path: "sprites/Mountains/Mountain1.png",
    },
    {
        name: "Mountain2",
        type: "sprite",
        path: "sprites/Mountains/Mountain2.png",
    },
    {
        name: "Mountain3",
        type: "sprite",
        path: "sprites/Mountains/Mountain3.png",
    },
    {
        name: "Mountain4",
        type: "sprite",
        path: "sprites/Mountains/Mountain4.png",
    },
    {
        name: "Mountain5",
        type: "sprite",
        path: "sprites/Mountains/Mountain5.png",
    },

    // Birdie
    {
        name: "Bird",
        type: "sprite",
        path: "sprites/Bird/Bird.png",
    },
    {
        name: "BirdShadow",
        type: "gltfModel",
        path: "models/Bird/Bird.gltf",
    },

    // Clouds
    {
        name: "Cloud1",
        type: "sprite",
        path: "sprites/Clouds/Cloud1.png",
    },
    {
        name: "Cloud2",
        type: "sprite",
        path: "sprites/Clouds/Cloud2.png",
    },
    {
        name: "Cloud3",
        type: "sprite",
        path: "sprites/Clouds/Cloud3.png",
    },
    {
        name: "Cloud4",
        type: "sprite",
        path: "sprites/Clouds/Cloud4.png",
    },
    {
        name: "Cloud5",
        type: "sprite",
        path: "sprites/Clouds/Cloud5.png",
    },
    {
        name: "Cloud6",
        type: "sprite",
        path: "sprites/Clouds/Cloud6.png",
    },

    {
        name: "CloudSus",
        type: "sprite",
        path: "sprites/Clouds/CloudSus.png",
    },

    {
        name: "Balloon",
        type: "sprite",
        path: "sprites/Balloon/Balloon.png",
    },

    // Sunset
    {
        name: "Sunset",
        type: "texture",
        path: "textures/sunset/Sunset.png",
    },

    // Wind
    {
        name: "Wind",
        type: "texture",
        path: "textures/wind/Wind.png",
    },

    // Text
    {
        name: "ComingSoon",
        type: "sprite",
        path: "sprites/Text/ComingSoon.png",
    },
    {
        name: "ScrollDown",
        type: "sprite",
        path: "sprites/Text/ScrollDown.png",
    },
    {
        name: "SwipeUp",
        type: "sprite",
        path: "sprites/Text/SwipeUp.png",
    },
    {
        name: "Copyright",
        type: "sprite",
        path: "sprites/Text/Copyright.png",
    },

    // Dev Sprites
    {
        name: "dev1",
        type: "sprite",
        path: "sprites/WildWesters/dev/1.png",
    },
    {
        name: "dev1Shadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/dev1.gltf",
    },
    {
        name: "dev2",
        type: "sprite",
        path: "sprites/WildWesters/dev/2.png",
    },
    {
        name: "dev2Shadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/dev2.gltf",
    },
    {
        name: "dev3",
        type: "sprite",
        path: "sprites/WildWesters/dev/3.png",
    },
    {
        name: "dev3Shadow",
        type: "gltfModel",
        path: "models/WildWesters/glTF/dev3.gltf",
    },

    {
        name: "blush",
        type: "sprite",
        path: "sprites/WildWesters/dev/blush.png",
    },
    {
        name: "rage",
        type: "sprite",
        path: "sprites/WildWesters/dev/rage.png",
    },
    {
        name: "sad",
        type: "sprite",
        path: "sprites/WildWesters/dev/sad.png",
    },
    {
        name: "shiny",
        type: "sprite",
        path: "sprites/WildWesters/dev/shiny.png",
    },

    // Product Scene
    {
        name: "Product",
        type: "gltfModel",
        path: "models/Product/Model.glb",
    },

    // Image Frames
    // Preview 1
    {
        name: "Preview1Image1",
        type: "texture",
        path: "textures/product/1/1.jpg",
    },
    {
        name: "Preview1Image2",
        type: "texture",
        path: "textures/product/1/2.jpg",
    },
    {
        name: "Preview1Image3",
        type: "texture",
        path: "textures/product/1/3.jpg",
    },
    {
        name: "Preview1Image4",
        type: "texture",
        path: "textures/product/1/4.jpg",
    },
    {
        name: "Preview1Image5",
        type: "texture",
        path: "textures/product/1/5.jpg",
    },
    {
        name: "Preview1Image6",
        type: "texture",
        path: "textures/product/1/6.jpg",
    },
    // Preview 2
    {
        name: "Preview2Image1",
        type: "texture",
        path: "textures/product/2/1.jpg",
    },
    {
        name: "Preview2Image2",
        type: "texture",
        path: "textures/product/2/2.jpg",
    },
    {
        name: "Preview2Image3",
        type: "texture",
        path: "textures/product/2/3.jpg",
    },
    {
        name: "Preview2Image4",
        type: "texture",
        path: "textures/product/2/4.jpg",
    },
    {
        name: "Preview2Image5",
        type: "texture",
        path: "textures/product/2/5.jpg",
    },
    {
        name: "Preview2Image6",
        type: "texture",
        path: "textures/product/2/6.jpg",
    },

    // Preview 3
    {
        name: "Preview3Image1",
        type: "texture",
        path: "textures/product/3/1.jpg",
    },
    {
        name: "Preview3Image2",
        type: "texture",
        path: "textures/product/3/2.jpg",
    },
    {
        name: "Preview3Image3",
        type: "texture",
        path: "textures/product/3/3.jpg",
    },
    {
        name: "Preview3Image4",
        type: "texture",
        path: "textures/product/3/4.jpg",
    },
    {
        name: "Preview3Image5",
        type: "texture",
        path: "textures/product/3/5.jpg",
    },
    {
        name: "Preview3Image6",
        type: "texture",
        path: "textures/product/3/6.jpg",
    },
    // Preview 4
    {
        name: "Preview4Image1",
        type: "texture",
        path: "textures/product/4/1.jpg",
    },
    {
        name: "Preview4Image2",
        type: "texture",
        path: "textures/product/4/2.jpg",
    },
    {
        name: "Preview4Image3",
        type: "texture",
        path: "textures/product/4/3.jpg",
    },
    {
        name: "Preview4Image4",
        type: "texture",
        path: "textures/product/4/4.jpg",
    },
    {
        name: "Preview4Image5",
        type: "texture",
        path: "textures/product/4/5.jpg",
    },
    {
        name: "Preview4Image6",
        type: "texture",
        path: "textures/product/4/6.jpg",
    },
    // Preview 5
    {
        name: "Preview5Image1",
        type: "texture",
        path: "textures/product/5/1.jpg",
    },
    {
        name: "Preview5Image2",
        type: "texture",
        path: "textures/product/5/2.jpg",
    },
    {
        name: "Preview5Image3",
        type: "texture",
        path: "textures/product/5/3.jpg",
    },
    {
        name: "Preview5Image4",
        type: "texture",
        path: "textures/product/5/4.jpg",
    },
    {
        name: "Preview5Image5",
        type: "texture",
        path: "textures/product/5/5.jpg",
    },
    {
        name: "Preview5Image6",
        type: "texture",
        path: "textures/product/5/6.jpg",
    },
    // Preview 6
    {
        name: "Preview6Image1",
        type: "texture",
        path: "textures/product/6/1.jpg",
    },
    {
        name: "Preview6Image2",
        type: "texture",
        path: "textures/product/6/2.jpg",
    },
    {
        name: "Preview6Image3",
        type: "texture",
        path: "textures/product/6/3.jpg",
    },
    {
        name: "Preview6Image4",
        type: "texture",
        path: "textures/product/6/4.jpg",
    },
    {
        name: "Preview6Image5",
        type: "texture",
        path: "textures/product/6/5.jpg",
    },
    {
        name: "Preview6Image6",
        type: "texture",
        path: "textures/product/6/6.jpg",
    },
    // Preview 7
    {
        name: "Preview7Image1",
        type: "texture",
        path: "textures/product/7/1.jpg",
    },
    {
        name: "Preview7Image2",
        type: "texture",
        path: "textures/product/7/2.jpg",
    },
    {
        name: "Preview7Image3",
        type: "texture",
        path: "textures/product/7/3.jpg",
    },
    {
        name: "Preview7Image4",
        type: "texture",
        path: "textures/product/7/4.jpg",
    },
    {
        name: "Preview7Image5",
        type: "texture",
        path: "textures/product/7/5.jpg",
    },
    {
        name: "Preview7Image6",
        type: "texture",
        path: "textures/product/7/6.jpg",
    },
    // Preview 8
    {
        name: "Preview8Image1",
        type: "texture",
        path: "textures/product/8/1.jpg",
    },
    {
        name: "Preview8Image2",
        type: "texture",
        path: "textures/product/8/2.jpg",
    },
    {
        name: "Preview8Image3",
        type: "texture",
        path: "textures/product/8/3.jpg",
    },
    {
        name: "Preview8Image4",
        type: "texture",
        path: "textures/product/8/4.jpg",
    },
    {
        name: "Preview8Image5",
        type: "texture",
        path: "textures/product/8/5.jpg",
    },
    {
        name: "Preview8Image6",
        type: "texture",
        path: "textures/product/8/6.jpg",
    },
    // Preview 9
    {
        name: "Preview9Image1",
        type: "texture",
        path: "textures/product/9/1.jpg",
    },
    {
        name: "Preview9Image2",
        type: "texture",
        path: "textures/product/9/2.jpg",
    },
    {
        name: "Preview9Image3",
        type: "texture",
        path: "textures/product/9/3.jpg",
    },
    {
        name: "Preview9Image4",
        type: "texture",
        path: "textures/product/9/4.jpg",
    },
    {
        name: "Preview9Image5",
        type: "texture",
        path: "textures/product/9/5.jpg",
    },
    {
        name: "Preview9Image6",
        type: "texture",
        path: "textures/product/9/6.jpg",
    },
    // Preview 10
    {
        name: "Preview10Image1",
        type: "texture",
        path: "textures/product/10/1.jpg",
    },
    {
        name: "Preview10Image2",
        type: "texture",
        path: "textures/product/10/2.jpg",
    },
    {
        name: "Preview10Image3",
        type: "texture",
        path: "textures/product/10/3.jpg",
    },
    {
        name: "Preview10Image4",
        type: "texture",
        path: "textures/product/10/4.jpg",
    },
    {
        name: "Preview10Image5",
        type: "texture",
        path: "textures/product/10/5.jpg",
    },
    {
        name: "Preview10Image6",
        type: "texture",
        path: "textures/product/10/6.jpg",
    },
    // Preview 11
    {
        name: "Preview11Image1",
        type: "texture",
        path: "textures/product/11/1.jpg",
    },
    {
        name: "Preview11Image2",
        type: "texture",
        path: "textures/product/11/2.jpg",
    },
    {
        name: "Preview11Image3",
        type: "texture",
        path: "textures/product/11/3.jpg",
    },
    {
        name: "Preview11Image4",
        type: "texture",
        path: "textures/product/11/4.jpg",
    },
    {
        name: "Preview11Image5",
        type: "texture",
        path: "textures/product/11/5.jpg",
    },
    {
        name: "Preview11Image6",
        type: "texture",
        path: "textures/product/11/6.jpg",
    },
    // Preview 12
    {
        name: "Preview12Image1",
        type: "texture",
        path: "textures/product/12/1.jpg",
    },
    {
        name: "Preview12Image2",
        type: "texture",
        path: "textures/product/12/2.jpg",
    },
    {
        name: "Preview12Image3",
        type: "texture",
        path: "textures/product/12/3.jpg",
    },
    {
        name: "Preview12Image4",
        type: "texture",
        path: "textures/product/12/4.jpg",
    },
    {
        name: "Preview12Image5",
        type: "texture",
        path: "textures/product/12/5.jpg",
    },
    {
        name: "Preview12Image6",
        type: "texture",
        path: "textures/product/12/6.jpg",
    },
    // Back
    {
        name: "BackImage1",
        type: "texture",
        path: "textures/product/back/1.jpg",
    },
    {
        name: "BackImage2",
        type: "texture",
        path: "textures/product/back/2.jpg",
    },
    {
        name: "BackImage3",
        type: "texture",
        path: "textures/product/back/3.jpg",
    },
    {
        name: "BackImage4",
        type: "texture",
        path: "textures/product/back/4.jpg",
    },
    {
        name: "BackImage5",
        type: "texture",
        path: "textures/product/back/5.jpg",
    },
    {
        name: "BackImage6",
        type: "texture",
        path: "textures/product/back/6.jpg",
    },
    {
        name: "BackImage7",
        type: "texture",
        path: "textures/product/back/7.jpg",
    },
    {
        name: "BackImage8",
        type: "texture",
        path: "textures/product/back/8.jpg",
    },
    {
        name: "BackImage9",
        type: "texture",
        path: "textures/product/back/9.jpg",
    },
    {
        name: "BackImage10",
        type: "texture",
        path: "textures/product/back/10.jpg",
    },
    {
        name: "BackImage11",
        type: "texture",
        path: "textures/product/back/11.jpg",
    },
    {
        name: "BackImage12",
        type: "texture",
        path: "textures/product/back/12.jpg",
    },

    // Compare Scene
    {
        name: "Banana",
        type: "gltfModel",
        path: "models/Compare/banana.glb",
    },

    {
        name: "Penguin",
        type: "gltfModel",
        path: "models/Compare/penguin.glb",
    },

    {
        name: "Elephant",
        type: "gltfModel",
        path: "models/Compare/elephant.glb",
    },

    {
        name: "Pyramid",
        type: "gltfModel",
        path: "models/Compare/pyramid.glb",
    },

    {
        name: "Eiffel",
        type: "gltfModel",
        path: "models/Compare/eiffel.glb",
    },

    {
        name: "Everest",
        type: "gltfModel",
        path: "models/Compare/everest.glb",
    },

    {
        name: "Moon",
        type: "gltfModel",
        path: "models/Compare/moon.glb",
    },

    //Skybox
    {
        name: "EnvironmentMap",
        type: "cubeTexture",
        path: [
            "textures/product/skybox/px.png",
            "textures/product/skybox/nx.png",
            "textures/product/skybox/py.png",
            "textures/product/skybox/ny.png",
            "textures/product/skybox/pz.png",
            "textures/product/skybox/nz.png",
        ],
    },

    {
        name: "cat",
        type: "texture",
        path: "images/cat.png",
    },
];
