import * as THREE from "three";
import Experience from "../../Experience.js";
import TWEEN from "@tweenjs/tween.js";

const FADE_IN_DURATION = 3000;
const FADE_OUT_DURATION = 1000;

export default class CompareObject {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.product.scene;
        this.debug = this.experience.debug;

        // Resource
        this.resources = this.experience.resources;

        this.tweens = [];
        this.visibleTimeouts = [];
    }

    setDebug() {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("compareObject");

            this.debugFolder
                .add(this.model.position, "x")
                .name("x")
                .min(50000)
                .max(150000)
                .step(1);

            this.debugFolder
                .add(this.model.position, "y")
                .name("y")
                .min(-500000)
                .max(-50000)
                .step(1);

            this.debugFolder
                .add(this.model.position, "z")
                .name("z")
                .min(-600000)
                .max(-200000)
                .step(1);

            this.debugFolder
                .add(this.model.rotation, "y")
                .name("rotation y")
                .min(0)
                .max(5)
                .step(0.01);
        }
    }

    setModel() {
        this.meshes = [];
        this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                this.meshes.push(child);
                child.material = new THREE.MeshBasicMaterial({
                    map: child.material.map,
                    transparent: true,
                    opacity: 0,
                });
            }
        });
    }

    addVisibleTimeout(id) {
        this.visibleTimeouts.push(id);
    }

    clearVisibleTimeout() {
        for (let i = 0; i < this.visibleTimeouts.length; i++) {
            clearTimeout(this.visibleTimeouts[i]);
        }
        this.visibleTimeouts = [];
    }

    toggleVisible(show) {
        this.clearVisibleTimeout();

        this.meshes.forEach((mesh) => {
            mesh.castShadow = show;
            mesh.receiveShadow = show;
        });

        if (show) {
            this.meshes.forEach((mesh) => {
                mesh.visible = show;
            });
            this.tweenOpacity(1, FADE_IN_DURATION);
        } else {
            // Fade out when changing page.
            this.tweenOpacity(0, FADE_OUT_DURATION);
            this.addVisibleTimeout(
                setTimeout(() => {
                    this.meshes.forEach((mesh) => {
                        mesh.visible = show;
                    });
                }, FADE_OUT_DURATION)
            );
        }
    }

    tweenOpacity(targetOpacity, duration) {
        if (this.tweens.length > 0) {
            this.tweens.forEach((tween) => tween.stop());
        }

        this.meshes.forEach((mesh) => {
            this.tweens.push(
                new TWEEN.Tween(mesh.material)
                    .to(
                        {
                            opacity: targetOpacity,
                        },
                        duration
                    )
                    .easing(TWEEN.Easing.Cubic.Out)
                    .start()
            );
        });
    }
}
