import * as THREE from "three";
import Experience, {
    SCENE_HOME,
    SCENE_WHAT,
    SCENE_WHO,
} from "../Experience.js";

import { SpriteAnimator } from "../Utils/SpriteAnimator.js";
import GetColorBetweenColors from "../Utils/Color.js";

const xPosRange = [35, -35];
const zPosRange = [5, 46];
const animationFrames = 6;

// Shared
let spriteResources = [];
let modelResources = [];
let invisMaterial = new THREE.MeshBasicMaterial({
    color: 0x000000,
    transparent: true,
    opacity: 0.001,
});

export default class WildWester {
    constructor(dev) {
        this.dev = dev;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                [
                    this.resources.items.egg0,
                    this.resources.items.egg1,
                    this.resources.items.egg2,
                    this.resources.items.egg3,
                    this.resources.items.egg4,
                    this.resources.items.egg5,
                    this.resources.items.egg6,
                    this.resources.items.egg7,
                    this.resources.items.egg8,
                    this.resources.items.egg9,
                    this.resources.items.egg10,
                    this.resources.items.egg11,
                    this.resources.items.egg12,
                    this.resources.items.egg13,
                    this.resources.items.egg14,
                    this.resources.items.egg15,
                ],
                [
                    this.resources.items.donut0,
                    this.resources.items.donut1,
                    this.resources.items.donut2,
                    this.resources.items.donut3,
                    this.resources.items.donut4,
                    this.resources.items.donut5,
                    this.resources.items.donut6,
                    this.resources.items.donut7,
                    this.resources.items.donut8,
                    this.resources.items.donut9,
                    this.resources.items.donut10,
                    this.resources.items.donut11,
                    this.resources.items.donut12,
                    this.resources.items.donut13,
                    this.resources.items.donut14,
                    this.resources.items.donut15,
                ],
                [
                    this.resources.items.tomato0,
                    this.resources.items.tomato1,
                    this.resources.items.tomato2,
                    this.resources.items.tomato3,
                    this.resources.items.tomato4,
                    this.resources.items.tomato5,
                    this.resources.items.tomato6,
                    this.resources.items.tomato7,
                    this.resources.items.tomato8,
                    this.resources.items.tomato9,
                    this.resources.items.tomato10,
                    this.resources.items.tomato11,
                    this.resources.items.tomato12,
                    this.resources.items.tomato13,
                    this.resources.items.tomato14,
                    this.resources.items.tomato15,
                ],
                [
                    this.resources.items.pickle0,
                    this.resources.items.pickle1,
                    this.resources.items.pickle2,
                    this.resources.items.pickle3,
                    this.resources.items.pickle4,
                    this.resources.items.pickle5,
                    this.resources.items.pickle6,
                    this.resources.items.pickle7,
                    this.resources.items.pickle8,
                    this.resources.items.pickle9,
                    this.resources.items.pickle10,
                    this.resources.items.pickle11,
                    this.resources.items.pickle12,
                    this.resources.items.pickle13,
                    this.resources.items.pickle14,
                    this.resources.items.pickle15,
                ],
                [
                    this.resources.items.toast0,
                    this.resources.items.toast1,
                    this.resources.items.toast2,
                    this.resources.items.toast3,
                    this.resources.items.toast4,
                    this.resources.items.toast5,
                    this.resources.items.toast6,
                    this.resources.items.toast7,
                    this.resources.items.toast8,
                    this.resources.items.toast9,
                    this.resources.items.toast10,
                    this.resources.items.toast11,
                    this.resources.items.toast12,
                    this.resources.items.toast13,
                    this.resources.items.toast14,
                    this.resources.items.toast15,
                ],
                [
                    this.resources.items.cheese0,
                    this.resources.items.cheese1,
                    this.resources.items.cheese2,
                    this.resources.items.cheese3,
                    this.resources.items.cheese4,
                    this.resources.items.cheese5,
                    this.resources.items.cheese6,
                    this.resources.items.cheese7,
                    this.resources.items.cheese8,
                    this.resources.items.cheese9,
                    this.resources.items.cheese10,
                    this.resources.items.cheese11,
                    this.resources.items.cheese12,
                    this.resources.items.cheese13,
                    this.resources.items.cheese14,
                    this.resources.items.cheese15,
                ],
            ];
        }
        if (modelResources.length == 0) {
            modelResources = [
                this.resources.items.EggShadow,
                this.resources.items.DonutShadow,
                this.resources.items.TomatoShadow,
                this.resources.items.PickleShadow,
                this.resources.items.ToastShadow,
                this.resources.items.CheeseShadow,
            ];
        }

        this.shape = Math.floor(Math.random() * spriteResources.length);
        this.color = Math.floor(
            Math.random() * spriteResources[this.shape].length
        );

        if (this.dev) {
            this.modelResource =
                this.resources.items.devEggShadow.scene.clone();
        } else {
            this.modelResource = modelResources[this.shape].scene.clone();
        }

        this.runDuration = 500;
        this.moveSpeed = 0.0035;
        this.tilesHoriz = 6;
        this.tilesVert = 1;

        this.group = new THREE.Group();
        this.setSprite();
        this.setShadow();
    }

    setShadow() {
        this.shadowModel = this.modelResource;
        this.shadowModel.rotation.x = Math.PI * 0.5;
        this.shadowModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = invisMaterial;
            }
        });
        if (this.dev) {
            this.shadowModel.scale.set(11, 11, 11);
            this.shadowModel.position.y = 0.4;
            this.shadowModel.position.x = -0.4;
        } else {
            this.shadowModel.scale.set(28, 28, 28);
            this.shadowModel.position.y = 1;
            this.shadowModel.position.x = -1;
        }
        this.shadowModel.children.forEach((obj) => {
            obj.layers.set(this.experience.world.HOME_LAYER);
        });
        this.updateShadow(this.spriteAnimator.currentTile);

        this.group.add(this.shadowModel);
    }

    // Updates shadow to the current frame.
    updateShadow(currentFrame) {
        this.shadowModel.children[
            (currentFrame + animationFrames - 1) % animationFrames
        ].castShadow = false;
        this.shadowModel.children[currentFrame].castShadow = true;
    }

    setSprite() {
        this.randomTimeOffset1 = Math.random() * 3000;
        this.randomTimeOffset2 = Math.random() * 3000;

        // Resource
        if (this.dev) {
            this.resources = this.resources.items.eggDev.clone();
            this.zPosOffset = 0;
            this.speedMultipler = 0.4;
        } else {
            this.speedMultipler = 1.3 + (Math.random() - 0.5) * 1;

            this.resources = spriteResources[this.shape][this.color].clone();
            this.zPosOffset =
                zPosRange[0] + Math.random() * (zPosRange[1] - zPosRange[0]);
        }

        // Animated Sprite
        this.spriteAnimator = new SpriteAnimator(
            this.resources,
            this.tilesHoriz,
            this.tilesVert,
            this
        );
        this.spriteAnimator.sprite.layers.set(this.experience.world.HOME_LAYER);
        this.group.add(this.spriteAnimator.sprite);

        if (this.dev) {
            this.group.position.set(6, 0.3, -4);
            this.spriteAnimator.sprite.scale.set(0.8, 0.8, 0.8);
        } else {
            this.group.position.set(
                xPosRange[0] + Math.random() * (xPosRange[1] - xPosRange[0]),
                0.73,
                -1
            );
            this.spriteAnimator.sprite.scale.set(2, 2, 2);
        }

        this.spriteAnimator.loop([0, 1, 2, 3, 4, 5], this.runDuration);

        this.scene.add(this.group);
    }

    reduceOpacity(bool) {
        if (bool) {
            this.spriteAnimator.sprite.material.opacity = 0.25;
        } else {
            this.spriteAnimator.sprite.material.opacity = 1;
        }
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.spriteAnimator.sprite.material.color.set(newColor);

        if (this.dev) {
            this.group.position.x +=
                -this.moveSpeed * this.speedMultipler * this.time.delta;
        } else {
            this.group.position.x +=
                -this.moveSpeed * this.speedMultipler * this.time.delta;
            this.group.position.z =
                Math.sin(
                    (this.time.elapsed + this.randomTimeOffset1) * 0.0005
                ) *
                    0.05 +
                Math.sin(
                    (300 + (this.time.elapsed + this.randomTimeOffset2)) *
                        0.0005
                ) +
                this.zPosOffset;
        }

        if (this.group.position.x < xPosRange[1]) {
            if (this.dev) {
                this.group.position.x = xPosRange[0];
            } else {
                this.speedMultipler = 1.3 + (Math.random() - 0.5) * 1;
                //get new z position
                this.group.position.x = xPosRange[0];
            }
        }

        this.spriteAnimator.update();
    }
}
