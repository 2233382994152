import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Floor from "./Floor.js";
import Foliage from "./Foliage.js";
import Mountain from "./Mountain.js";
import Sky from "./Sky.js";
import Structure from "./Structure.js";
import Sun from "./Sun.js";
import Title from "./Title.js";
import Cloud from "./Cloud.js";
import WildWester from "./WildWester.js";
import Balloon from "./Balloon.js";
import ScrollDown from "./ScrollDown.js";
import Wind from "./Wind.js";
import WildWesterDev from "./WildWesterDev.js";
import Overlay from "./Overlay.js";
import Emotion from "./Emotion.js";

export default class World {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;

        // Layers
        this.HOME_LAYER = 1;
        this.HOME_BG = 2;
        this.UI_LAYER = 9;
        this.OVERLAY_LAYER = 10;

        // CSS Objects

        // Wait for resources
        this.resources.on("ready", () => {
            // Environment
            this.environment = new Environment();
            this.sun = new Sun();
            this.floor = new Floor();
            this.sky = new Sky();

            // Title
            this.title = new Title();
            this.scrollDown = new ScrollDown();

            // Loading Overlay
            this.overlay = new Overlay(this.scene, this.OVERLAY_LAYER);

            // Wild Westers
            this.devWildWester = new WildWester(true);
            this.WildWesters = Array.from(
                { length: 100 },
                () => new WildWester(false)
            );

            // Foliage
            const farObjects = [9, 1, 2, 10, 11];
            this.farObject = new Foliage(
                farObjects[Math.floor(Math.random() * farObjects.length)],
                true
            );

            // Arranged in order of more random (position) to least.
            this.Sign = Array.from({ length: 4 }, () => new Foliage(9));
            this.Fence = Array.from({ length: 6 }, () => new Foliage(5));
            this.Cowskull1 = Array.from({ length: 2 }, () => new Foliage(20));
            this.Cactus1 = Array.from({ length: 2 }, () => new Foliage(1));
            this.Cactus2 = Array.from({ length: 3 }, () => new Foliage(2));
            this.Cactus5 = Array.from({ length: 2 }, () => new Foliage(10));
            this.Cactus6 = Array.from({ length: 4 }, () => new Foliage(11));
            this.Cactus7 = Array.from({ length: 3 }, () => new Foliage(12));
            this.Cactus8 = Array.from({ length: 4 }, () => new Foliage(13));
            this.Cactus3 = Array.from({ length: 5 }, () => new Foliage(3));
            this.Cactus4 = Array.from({ length: 4 }, () => new Foliage(4));
            this.Bush = Array.from({ length: 5 }, () => new Foliage(0));
            this.Branch1 = Array.from({ length: 3 }, () => new Foliage(14));
            this.Branch2 = Array.from({ length: 4 }, () => new Foliage(15));
            this.Branch3 = Array.from({ length: 4 }, () => new Foliage(16));
            this.Rock1 = Array.from({ length: 18 }, () => new Foliage(6));
            this.Rock2 = Array.from({ length: 18 }, () => new Foliage(7));
            this.Rock3 = Array.from({ length: 18 }, () => new Foliage(8));
            this.Rock4 = Array.from({ length: 3 }, () => new Foliage(17));
            this.Rock5 = Array.from({ length: 3 }, () => new Foliage(18));
            this.Rock6 = Array.from({ length: 3 }, () => new Foliage(19));
            this.Foliage = this.Sign.concat(
                this.Fence,
                this.Cowskull1,
                this.Cactus1,
                this.Cactus2,
                this.Cactus5,
                this.Cactus6,
                this.Cactus7,
                this.Cactus8,
                this.Cactus3,
                this.Cactus4,
                this.Bush,
                this.Branch1,
                this.Branch2,
                this.Branch3,
                this.Rock1,
                this.Rock2,
                this.Rock3,
                this.Rock4,
                this.Rock5,
                this.Rock6
            );
            this.Structures = [
                new Structure(0),
                new Structure(1),
                new Structure(2),
                new Structure(3),
                new Structure(4),
                new Structure(5),
                new Structure(6),
            ];

            // BG Mountains
            this.Mountains = [
                new Mountain(0),
                new Mountain(2),
                new Mountain(3),
                new Mountain(1),
                new Mountain(4),
                new Mountain(0),
            ];
            this.FarMountains = [
                new Mountain(4, true),
                new Mountain(1, true),
                new Mountain(2, true),
                new Mountain(3, true),
                new Mountain(0, true),
                new Mountain(2, true),
                new Mountain(1, true),
            ];

            // Clouds
            this.Clouds0 = Array.from({ length: 4 }, () => new Cloud(0, 4));
            this.Clouds1 = Array.from({ length: 5 }, () => new Cloud(1, 5));
            this.Clouds2 = Array.from({ length: 6 }, () => new Cloud(2, 6));
            this.Clouds3 = Array.from({ length: 7 }, () => new Cloud(3, 7));

            // Balloon
            this.Balloon = new Balloon();

            // Wind
            this.devWind = Array.from(
                { length: 1 },
                () => new Wind(true, false)
            );
            this.Wind = Array.from({ length: 8 }, () => new Wind(false, false));
            this.Sand = Array.from({ length: 50 }, () => new Wind(false, true));
            this.devSand = Array.from(
                { length: 25 },
                () => new Wind(true, true)
            );

            // The Team
            this.team = [
                new WildWesterDev(0),
                new WildWesterDev(1),
                new WildWesterDev(2),
            ];
            this.emotion = new Emotion();

            this.UpdateObjects = [
                [this.devWildWester],
                this.WildWesters,
                [this.farObject],
                this.Foliage,
                this.Structures,
                this.Mountains,
                this.FarMountains,

                this.Clouds0,
                this.Clouds1,
                this.Clouds2,
                this.Clouds3,
                [this.Balloon],
                this.devWind,
                this.Wind,
                this.Sand,
                this.devSand,
                this.team,
                [this.emotion],
            ];
        });
    }

    reduceOpacity(bool) {
        this.WildWesters.forEach((item) => {
            item.reduceOpacity(bool);
        });
        this.Foliage.forEach((item) => {
            item.reduceOpacity(bool);
        });
    }

    update() {
        if (this.time.delta < 1000) {
            // Update Environment
            if (this.environment) {
                this.environment.update();
            }
            if (this.sun) {
                this.sun.update();
            }
            if (this.sky) {
                this.sky.update();
            }

            // Update Text Objects
            if (this.title) {
                this.title.update();
            }
            if (this.scrollDown) {
                this.scrollDown.update();
            }

            // Update Foliage
            if (this.UpdateObjects) {
                this.UpdateObjects.forEach((array) =>
                    array.forEach((item) => {
                        item.update();
                    })
                );
            }
        }
    }
}
