import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

const xPosRange = [-30, 30];
const zPosRange = [4, 47];

// Shared
let spriteResources = [];
let modelResources = [];
let existingCoords = [];
let invisMaterial = new THREE.MeshBasicMaterial({
    color: 0x000000,
    transparent: true,
    opacity: 0.001,
});

export default class Foliage {
    constructor(type, farObject) {
        this.type = type;
        this.farObject = farObject;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                this.resources.items.Bush1,
                this.resources.items.Cactus1,
                this.resources.items.Cactus2,
                this.resources.items.Cactus3,
                this.resources.items.Cactus4,
                this.resources.items.Fence1,
                this.resources.items.Rock1,
                this.resources.items.Rock2,
                this.resources.items.Rock3,
                this.resources.items.Sign1,
                this.resources.items.Cactus5,
                this.resources.items.Cactus6,
                this.resources.items.Cactus7,
                this.resources.items.Cactus8,
                this.resources.items.Branch1,
                this.resources.items.Branch2,
                this.resources.items.Branch3,
                this.resources.items.Rock4,
                this.resources.items.Rock5,
                this.resources.items.Rock6,
                this.resources.items.Cowskull1,
            ];
            spriteResources.forEach((item) => {
                item.magFilter = THREE.NearestFilter;
                item.minFilter = THREE.NearestFilter;
            });
        }
        if (modelResources.length == 0) {
            modelResources = [
                this.resources.items.Bush1Model,
                this.resources.items.Cactus1Model,
                this.resources.items.Cactus2Model,
                this.resources.items.Cactus3Model,
                this.resources.items.Cactus4Model,
                this.resources.items.Fence1Model,
                this.resources.items.Rock1Model,
                this.resources.items.Rock2Model,
                this.resources.items.Rock3Model,
                this.resources.items.Sign1Model,
                this.resources.items.Cactus5Model,
                this.resources.items.Cactus6Model,
                this.resources.items.Cactus7Model,
                this.resources.items.Cactus8Model,
                this.resources.items.Branch1Model,
                this.resources.items.Branch2Model,
                this.resources.items.Branch3Model,
                this.resources.items.Rock4Model,
                this.resources.items.Rock5Model,
                this.resources.items.Rock6Model,
                this.resources.items.Cowskull1Model,
            ];
        }

        this.modelResource = modelResources[this.type].scene.clone();

        this.group = new THREE.Group();
        this.setSprite();
        this.setShadow();
    }

    reduceOpacity(bool) {
        if (bool) {
            this.sprite.material.opacity = 0.15;
        } else {
            this.sprite.material.opacity = 1;
        }
    }

    setShadow() {
        this.shadowModel = this.modelResource;
        this.shadowModel.rotation.x = Math.PI * 0.5;
        this.shadowModel.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = invisMaterial;
            }
        });
        if (this.farObject) {
            this.shadowModel.scale.set(14.25, 14.25, 14.25);
            this.shadowModel.position.y = 0.79;
            this.shadowModel.position.x = -0.77;
        } else {
            this.shadowModel.scale.set(38, 38, 38);
            this.shadowModel.position.y = 2.07;
            this.shadowModel.position.x = -2.053;
        }

        this.shadowModel.children[0].castShadow = true;
        this.shadowModel.children[0].layers.set(
            this.experience.world.HOME_LAYER
        );

        this.group.add(this.shadowModel);
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: spriteResources[this.type] })
        );
        this.sprite.layers.set(this.experience.world.HOME_LAYER);

        this.group.add(this.sprite);

        // Set position.
        if (this.farObject) {
            this.group.position.set(-0.6, 0.43, -4);
            this.sprite.scale.set(1.5, 1.5, 1.5);
        } else {
            // Prevent Models from being too close.
            let xPos;
            let zPos;
            let counter = 0;
            while (true) {
                xPos =
                    xPosRange[0] +
                    Math.random() * (xPosRange[1] - xPosRange[0]);
                zPos =
                    zPosRange[0] +
                    Math.random() * (zPosRange[1] - zPosRange[0]);
                if (counter > 10) {
                    // Give up placing it spaced.
                    break;
                }

                let closeFound = false;
                for (let x = 0; x < existingCoords.length; x++) {
                    if (
                        Math.abs(existingCoords[x][1] - zPos) < 4 &&
                        Math.abs(existingCoords[x][0] - xPos) < 1
                    ) {
                        closeFound = true;
                        break;
                    }
                }
                if (closeFound) {
                    counter++;
                    continue;
                } else {
                    existingCoords.push([xPos, zPos]);
                    break;
                }
            }
            this.group.position.set(xPos, 1.16, zPos);
            this.sprite.scale.set(4, 4, 4);
        }

        this.scene.add(this.group);
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.sprite.material.color.set(newColor);
    }
}
