import * as THREE from "three";
import Experience from "../Experience";

export class SpriteAnimator {
    constructor(texture, tilesHoriz, tilesVert, parent, disableMagFilter) {
        this.disableMagFilter = disableMagFilter;

        if (parent) {
            this.parent = parent;
        }

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        this.tilesHoriz = tilesHoriz;
        this.tilesVert = tilesVert;
        this.map = texture;

        this.elapsed = 0;
        this.maxDisplayTime = 0;
        this.currentTile = 0;

        this.setSprite();
    }

    setSprite() {
        if (!this.disableMagFilter) {
            this.map.magFilter = THREE.NearestFilter;
            this.map.minFilter = THREE.NearestFilter;
        }
        this.map.repeat.set(1 / this.tilesHoriz, 1);

        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: this.map })
        );
    }

    loop(playSpriteIndices, totalDuration) {
        this.playSpriteIndices = playSpriteIndices;
        this.runningTileArrayIndex = Math.floor(
            Math.random() * this.playSpriteIndices.length
        );
        this.currentTile = this.playSpriteIndices[this.runningTileArrayIndex];
        this.maxDisplayTime = totalDuration / this.playSpriteIndices.length;
    }

    update() {
        this.elapsed += this.time.delta;

        if (this.maxDisplayTime > 0 && this.elapsed >= this.maxDisplayTime) {
            this.elapsed = 0;
            this.runningTileArrayIndex =
                (this.runningTileArrayIndex + 1) %
                this.playSpriteIndices.length;
            this.currentTile =
                this.playSpriteIndices[this.runningTileArrayIndex];
            if (this.parent.updateShadow) {
                this.parent.updateShadow(this.currentTile);
            }
            this.map.offset.x =
                (this.currentTile % this.tilesHoriz) / this.tilesHoriz;
            this.map.offset.y =
                (this.tilesVert -
                    Math.floor(this.currentTile / this.tilesHoriz) -
                    1) /
                this.tilesVert;
        }
    }
}
