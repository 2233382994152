import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

let spriteResources = [];
let susCloudSprite;

const xPosRange = [
    [-40, 40],
    [-40, 40],
    [-40, 40],
    [-40, 40],
];
const zPosRange = [
    [-2, -0.35],
    [-0.6, -0.35],
    [-7.9, -5],
    [-8, -8],
];
const yPosRange = [
    [12, 20],
    [7, 10],
    [3.2, 4.1],
    [1, 1.5],
];
const scaleValues = [17, 10, 7, 3.2];
const opacityValues = [0.8, 0.55, 0.55, 0.55];
const moveSpeeds = [0.0001, 0.00006, 0.000035, 0.000015];
const cloudSpeedMultiplier = 1;

let cloudCount = [0, 0, 0, 0];
let susCloudSpawned = false;

const TEST_TYPE = -1;

export default class Cloud {
    constructor(farType, totalClouds) {
        this.farType = farType;
        this.totalClouds = totalClouds;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                this.resources.items.Cloud1,
                this.resources.items.Cloud2,
                this.resources.items.Cloud3,
                this.resources.items.Cloud4,
                this.resources.items.Cloud5,
                this.resources.items.Cloud6,
            ];

            susCloudSprite = this.resources.items.CloudSus;

            spriteResources.forEach((item) => {
                item.magFilter = THREE.NearestFilter;
                item.minFilter = THREE.NearestFilter;
            });
        }

        this.spriteID = Math.floor(Math.random() * spriteResources.length);

        this.moveSpeed = moveSpeeds[this.farType];

        this.group = new THREE.Group();
        this.setSprite();
        cloudCount[this.farType] += 1;
    }

    setSprite() {
        let yPos;
        if (this.farType == 0 && cloudCount[this.farType] == 2) {
            susCloudSpawned = true;
            this.sprite = new THREE.Sprite(
                new THREE.SpriteMaterial({ map: susCloudSprite })
            );
            yPos = 17;
        } else {
            this.sprite = new THREE.Sprite(
                new THREE.SpriteMaterial({
                    map: spriteResources[this.spriteID],
                })
            );
            yPos =
                yPosRange[this.farType][0] +
                Math.random() *
                    (yPosRange[this.farType][1] - yPosRange[this.farType][0]);
        }

        this.sprite.layers.set(this.experience.world.HOME_LAYER);
        this.sprite.scale.set(
            scaleValues[this.farType],
            scaleValues[this.farType],
            scaleValues[this.farType]
        );
        this.sprite.material.opacity = opacityValues[this.farType];

        let xPos =
            ((xPosRange[this.farType][1] - xPosRange[this.farType][0]) /
                this.totalClouds) *
                cloudCount[this.farType] +
            xPosRange[this.farType][0];
        let zPos =
            zPosRange[this.farType][0] +
            Math.random() *
                (zPosRange[this.farType][1] - zPosRange[this.farType][0]);
        this.group.position.set(xPos, yPos, zPos);

        this.group.add(this.sprite);
        this.scene.add(this.group);
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.sprite.material.color.set(newColor);

        this.group.position.x +=
            -this.moveSpeed * cloudSpeedMultiplier * this.time.delta;

        if (this.group.position.x < xPosRange[this.farType][0]) {
            let xPos = xPosRange[this.farType][1];
            let zPos =
                zPosRange[this.farType][0] +
                Math.random() *
                    (zPosRange[this.farType][1] - zPosRange[this.farType][0]);
            let yPos =
                yPosRange[this.farType][0] +
                Math.random() *
                    (yPosRange[this.farType][1] - yPosRange[this.farType][0]);
            this.group.position.set(xPos, yPos, zPos);
        }
    }
}
