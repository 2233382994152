import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 0.15;

export default class Elephant extends CompareObject {
    constructor() {
        super();

        this.model = this.resources.items.Elephant.scene.clone();
        super.setModel();
        this.setPosition();
    }

    setPosition() {
        this.model.position.set(60, 0, -15);
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }
}
