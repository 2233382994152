import Experience from "./Experience/Experience.js";

import { inject } from "@vercel/analytics";
inject();

let x = window.location.pathname;

// privacy
if (x == "/privacy") {
    location.assign("https://app.wildwesters.io/privacy");
}

// terms
else if (x == "/terms") {
    location.assign("https://app.wildwesters.io/terms");
}

// wildwester
else if (x.indexOf("/wildwester/") == 0) {
    location.assign("https://app.wildwesters.io" + x);
}

// what
else if (x == "/what") {
    const experience = new Experience(
        document.querySelector("canvas.webgl"),
        true
    );
}

// default
else {
    const experience = new Experience(
        document.querySelector("canvas.webgl"),
        false
    );
}
