import * as THREE from "three";
import Experience from "../Experience.js";
import TWEEN from "@tweenjs/tween.js";

const SPOTLIGHT_INTENSITY = [3, 15];
const SPOTLIGHT_HEIGHT = 5;
const SPOTLIGHT_DISTANCE = 5;
const SHADOWMAP_SIZE = 2048;

const ROTATION_SPEED = 0.00015;
const LIGHT_BASE_COLOR = 0xffffff;

const POINTLIGHT_INTENSITY = [0, 1];

export default class Environment {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.product.scene;
        this.resources = this.experience.resources;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.dimmed = false;

        // this.setEnvironmentMap();
        this.rotatingSpotlightGroup = new THREE.Group();
        this.setLight();
    }

    setEnvironmentMap() {
        this.environmentMap = this.resources.items.EnvironmentMap;
        this.environmentMap.encoding = THREE.sRGBEncoding;
        this.scene.background = this.environmentMap;
        this.scene.environment = this.environmentMap;
    }

    brightLight(show) {
        // Return if already bright.
        if (show && !this.dimmed) return;

        let plIntensity = POINTLIGHT_INTENSITY[0];
        let slIntensity = SPOTLIGHT_INTENSITY[0];
        this.dimmed = !show;
        if (show) {
            plIntensity = POINTLIGHT_INTENSITY[1];
            slIntensity = SPOTLIGHT_INTENSITY[1];
        }

        new TWEEN.Tween(this.pointlight)
            .to(
                {
                    intensity: plIntensity,
                },
                2500
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        new TWEEN.Tween(this.spotlightFront)
            .to(
                {
                    intensity: slIntensity,
                },
                2500
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }

    scalePointLight(x) {
        this.tweenPointLight(1 / x, 1000);
    }

    tweenPointLight(scaleValue, duration) {
        if (this.lastPointIntensityTween) {
            this.lastPointIntensityTween.stop();
        }

        if (this.lastPointPositionTween) {
            this.lastPointPositionTween.stop();
        }

        this.lastPointIntensityTween = new TWEEN.Tween(this.pointlight)
            .to(
                {
                    intensity: Math.max(
                        POINTLIGHT_INTENSITY[1] * scaleValue * scaleValue,
                        0.015
                    ),
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();

        this.lastPointPositionTween = new TWEEN.Tween(this.pointlight.position)
            .to(
                {
                    y: 0.7 * scaleValue,
                    z: -0.7 * scaleValue,
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();
    }

    setLight() {
        this.lightTarget = new THREE.Object3D();
        this.lightTarget.position.set(0, 0, 0);

        // Rotating spotlight
        this.spotlightFront = new THREE.SpotLight(
            LIGHT_BASE_COLOR,
            SPOTLIGHT_INTENSITY[1]
        );
        this.spotlightFront.position.set(
            0,
            SPOTLIGHT_HEIGHT,
            SPOTLIGHT_DISTANCE
        );
        this.spotlightFront.shadow.mapSize.set(SHADOWMAP_SIZE, SHADOWMAP_SIZE);
        this.spotlightFront.penumbra = 0.5;
        this.spotlightFront.target = this.lightTarget;
        this.spotlightFront.far = 20;
        this.rotatingSpotlightGroup.add(this.spotlightFront);
        this.spotlightFront.castShadow = true;

        // Pointlight
        this.pointlight = new THREE.PointLight(
            LIGHT_BASE_COLOR,
            POINTLIGHT_INTENSITY[1]
        );
        this.pointlight.position.set(0, 0.7, -0.7);
        this.scene.add(this.pointlight);
        this.scene.add(this.rotatingSpotlightGroup);

        this.rotatingSpotlightGroup.rotation.y = -Math.PI / 4;
    }

    update() {
        this.rotatingSpotlightGroup.rotation.y +=
            ROTATION_SPEED * this.time.delta;
    }
}
