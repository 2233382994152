import * as THREE from "three";
import Experience from "../Experience.js";
import { SpriteAnimator } from "../Utils/SpriteAnimator.js";
import GetColorBetweenColors from "../Utils/Color.js";

const xPosRange = [35, -50];

// Shared
let spriteResource;

export default class Balloon {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        // Shared Resources
        this.resources = this.experience.resources;
        if (!spriteResource) {
            spriteResource = this.resources.items.Balloon;
        }

        this.animationDuration = 1600;
        this.moveSpeed = 0.0002;
        this.tilesHoriz = 6;
        this.tilesVert = 1;

        this.randomTimeOffset = Math.random() * 3000;

        this.group = new THREE.Group();
        this.setSprite();
    }

    setSprite() {
        this.resource = spriteResource.clone();

        // Animated Sprite
        this.spriteAnimator = new SpriteAnimator(
            this.resource,
            this.tilesHoriz,
            this.tilesVert,
            this,
            true
        );
        this.spriteAnimator.sprite.layers.set(this.experience.world.HOME_LAYER);
        this.group.add(this.spriteAnimator.sprite);

        this.group.position.set(10, 13, 2);
        this.spriteAnimator.sprite.scale.set(7, 7, 7);

        this.spriteAnimator.loop([0, 1, 2, 3, 4, 5], this.animationDuration);

        this.scene.add(this.group);
    }

    update() {
        // Update brightness to scroll value
        const newColor = GetColorBetweenColors(
            "#000000",
            "#ffffff",
            Math.min(1, this.experience.scroll.scrollProgress * 1.4)
        );
        this.spriteAnimator.sprite.material.color.set(newColor);

        this.group.position.x += -this.moveSpeed * this.time.delta;
        this.group.position.y =
            Math.sin((this.time.elapsed + this.randomTimeOffset) * 0.0005) *
                0.07 +
            13;

        if (this.group.position.x < xPosRange[1]) {
            this.group.position.x = xPosRange[0];
        }

        this.spriteAnimator.update();
    }
}
