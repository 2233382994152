import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

export default class Title {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;

        this.fadeInStart = 0.9;

        // Resource
        this.resources = this.experience.resources;
        this.resource = this.resources.items.Title;
        this.resource.magFilter = THREE.NearestFilter;
        // this.resource.minFilter = THREE.NearestFilter

        this.setSprite();
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: this.resource })
        );
        this.sprite.layers.set(this.experience.world.HOME_LAYER);

        const scale = 7.5;
        this.sprite.position.y = 14.5;
        this.sprite.scale.set(2.309 * scale, scale, scale);

        this.sprite.material.transparent = true;
        this.sprite.material.opacity = 0;

        this.scene.add(this.sprite);
    }

    update() {
        // Update opacity to scroll value
        let opacityValue = 0;
        if (this.scroll.scrollProgress > this.fadeInStart) {
            opacityValue =
                (this.scroll.scrollProgress - this.fadeInStart) /
                (1 - this.fadeInStart);
        }
        this.sprite.material.opacity +=
            (opacityValue - this.sprite.material.opacity) *
            this.time.delta *
            0.005;
    }
}
