import * as THREE from "three";
import Experience, { SCENE_HOME } from "../Experience.js";

export default class Environment {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.scroll = this.experience.scroll;
        this.time = this.experience.time;

        // Scroll-adjusted Values
        this.ySunPositionRanges = [1, 12];
        this.ySunPositionRange =
            this.ySunPositionRanges[1] - this.ySunPositionRanges[0];
        this.ambientLightIntensityRanges = [0, 2];
        this.ambientLightIntensityRange =
            this.ambientLightIntensityRanges[1] -
            this.ambientLightIntensityRanges[0];

        this.setSunLight();
        this.setAmbientLight();
    }

    update() {
        if (this.experience.activeScene == SCENE_HOME) {
            this.sunLight.position.y +=
                (this.ySunPositionRanges[0] +
                    this.scroll.scrollProgress * this.ySunPositionRange -
                    this.sunLight.position.y) *
                this.time.delta *
                0.005;
            this.ambientLight.intensity +=
                (this.ambientLightIntensityRanges[0] +
                    this.scroll.scrollProgress *
                        this.ambientLightIntensityRange -
                    this.ambientLight.intensity) *
                this.time.delta *
                0.005;
        }
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight("#ffffff", 0);
        this.ambientLight.layers.set(this.experience.world.HOME_LAYER);

        this.scene.add(this.ambientLight);
    }

    setSunLight() {
        this.sunLight = new THREE.DirectionalLight("#a65e00", 4);
        this.sunLight.castShadow = true;
        this.sunLight.shadow.camera.far = 50;
        this.sunLight.shadow.mapSize.set(4096, 4096);
        this.sunLight.shadow.normalBias = 0.05;
        this.sunLight.position.set(0, 1, -10);

        this.sunLight.shadow.camera.top = 28;
        this.sunLight.shadow.camera.top = 45;
        this.sunLight.shadow.camera.bottom = -5;
        this.sunLight.shadow.camera.right = 35;
        this.sunLight.shadow.camera.left = -35;

        this.sunLight.layers.set(this.experience.world.HOME_LAYER);

        this.scene.add(this.sunLight);
    }
}
