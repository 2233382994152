import EventEmitter from "./EventEmitter.js";

export default class Sizes extends EventEmitter {
    constructor() {
        super();

        this.width = document.documentElement.clientWidth;
        this.height = document.documentElement.clientHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);

        // Resize event
        window.addEventListener("resize", () => {
            this.width = document.documentElement.clientWidth;
            this.height = document.documentElement.clientHeight;
            this.pixelRatio = Math.min(window.devicePixelRatio, 2);
            this.trigger("resize");
        });
    }
}
