import * as THREE from "three";
import Experience from "../Experience.js";
import GetColorBetweenColors from "../Utils/Color.js";

const positionX = [-5, -29, -16.5, 8, 19, 31];
let currentIndex = 0;

const farPositionX = [-23, -11, 2.2, 13.8, 25, -36, 37];
let farIndex = 0;

// Shared
let spriteResources = [];

export default class Mountain {
    constructor(type, far) {
        this.far = far;

        // Prevent adding more structures than possible preset positions.
        if (this.far && farIndex >= farPositionX.length) {
            return;
        } else if (!this.far && currentIndex >= positionX.length) {
            return;
        }

        this.type = type;

        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.debug = this.experience.debug;

        // Shared Resources
        this.resources = this.experience.resources;
        if (spriteResources.length == 0) {
            spriteResources = [
                this.resources.items.Mountain1,
                this.resources.items.Mountain2,
                this.resources.items.Mountain3,
                this.resources.items.Mountain4,
                this.resources.items.Mountain5,
            ];
            spriteResources.forEach((item) => {
                item.magFilter = THREE.NearestFilter;
                item.minFilter = THREE.NearestFilter;
            });
        }

        this.group = new THREE.Group();
        this.setSprite();
    }

    setSprite() {
        this.sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({ map: spriteResources[this.type] })
        );
        this.sprite.layers.set(this.experience.world.HOME_LAYER);

        this.group.add(this.sprite);

        if (this.far) {
            this.sprite.scale.set(3, 3, 3);
            this.group.position.set(farPositionX[farIndex], 0.25, -5.5);
            farIndex++;
        } else {
            this.sprite.scale.set(6, 6, 6);
            this.group.position.set(positionX[currentIndex], 1.1, -5);
            currentIndex++;
        }

        this.scene.add(this.group);
    }

    update() {
        // Update brightness to scroll value
        if (this.far) {
            const newColor = GetColorBetweenColors(
                "#1f1f1f",
                "#9e9e9e",
                Math.min(1, this.experience.scroll.scrollProgress * 1.4)
            );
            this.sprite.material.color.set(newColor);
        } else {
            const newColor = GetColorBetweenColors(
                "#212121",
                "#ffffff",
                Math.min(1, this.experience.scroll.scrollProgress * 1.4)
            );
            this.sprite.material.color.set(newColor);
        }
    }
}
