import CompareObject from "./CompareObject.js";

const SCALE_VALUE = 0.1;

export default class Banana extends CompareObject {
    constructor() {
        super();

        this.model = this.resources.items.Banana.scene.clone();
        super.setModel();
        this.setPosition();
    }

    setPosition() {
        this.model.position.set(2.7, 1.9, -0.1);
        this.model.rotation.x = -Math.PI / 2;
        this.model.rotation.z = Math.PI / 6;
        this.model.scale.set(SCALE_VALUE, SCALE_VALUE, SCALE_VALUE);

        this.scene.add(this.model);
    }
}
