import * as THREE from "three";
import Experience from "../Experience.js";
import TWEEN from "@tweenjs/tween.js";
import Banana from "./Compare/Banana.js";
import Penguin from "./Compare/Penguin.js";
import Pyramid from "./Compare/Pyramid.js";
import Eiffel from "./Compare/Eiffel.js";
import Elephant from "./Compare/Elephant.js";
import Moon from "./Compare/Moon.js";
import Everest from "./Compare/Everest.js";

const STAND_OPEN_Z_ROTATION = Math.PI * 0.5;
const STAND_CLOSE_Z_ROTATION = 0;

const UPRIGHT_X_ROTATION = Math.PI * -0.075;
const FLAT_X_ROTATION = Math.PI * -0.5;

const OPEN_STAND_DURATION = 2500;
const CLOSE_STAND_DURATION = 1000;

const FLAT_DURATION = 1500;

export default class Product {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.product.scene;
        this.debug = this.experience.debug;
        this.time = this.experience.time;
        this.camera = this.experience.camera2;

        // Resource
        this.resources = this.experience.resources;
        this.model = this.resources.items.Product.scene;

        this.imageIndex = 0;
        this.images = [
            // Preview 1
            [
                this.resources.items.Preview1Image1,
                this.resources.items.Preview1Image2,
                this.resources.items.Preview1Image3,
                this.resources.items.Preview1Image4,
                this.resources.items.Preview1Image5,
                this.resources.items.Preview1Image6,
            ],
            // Preview 2
            [
                this.resources.items.Preview2Image1,
                this.resources.items.Preview2Image2,
                this.resources.items.Preview2Image3,
                this.resources.items.Preview2Image4,
                this.resources.items.Preview2Image5,
                this.resources.items.Preview2Image6,
            ],
            // Preview 3
            [
                this.resources.items.Preview3Image1,
                this.resources.items.Preview3Image2,
                this.resources.items.Preview3Image3,
                this.resources.items.Preview3Image4,
                this.resources.items.Preview3Image5,
                this.resources.items.Preview3Image6,
            ],
            // Preview 4
            [
                this.resources.items.Preview4Image1,
                this.resources.items.Preview4Image2,
                this.resources.items.Preview4Image3,
                this.resources.items.Preview4Image4,
                this.resources.items.Preview4Image5,
                this.resources.items.Preview4Image6,
            ],
            // Preview 5
            [
                this.resources.items.Preview5Image1,
                this.resources.items.Preview5Image2,
                this.resources.items.Preview5Image3,
                this.resources.items.Preview5Image4,
                this.resources.items.Preview5Image5,
                this.resources.items.Preview5Image6,
            ],
            // Preview 6
            [
                this.resources.items.Preview6Image1,
                this.resources.items.Preview6Image2,
                this.resources.items.Preview6Image3,
                this.resources.items.Preview6Image4,
                this.resources.items.Preview6Image5,
                this.resources.items.Preview6Image6,
            ],
            // Preview 7
            [
                this.resources.items.Preview7Image1,
                this.resources.items.Preview7Image2,
                this.resources.items.Preview7Image3,
                this.resources.items.Preview7Image4,
                this.resources.items.Preview7Image5,
                this.resources.items.Preview7Image6,
            ],
            // Preview 8
            [
                this.resources.items.Preview8Image1,
                this.resources.items.Preview8Image2,
                this.resources.items.Preview8Image3,
                this.resources.items.Preview8Image4,
                this.resources.items.Preview8Image5,
                this.resources.items.Preview8Image6,
            ],
            // Preview 9
            [
                this.resources.items.Preview9Image1,
                this.resources.items.Preview9Image2,
                this.resources.items.Preview9Image3,
                this.resources.items.Preview9Image4,
                this.resources.items.Preview9Image5,
                this.resources.items.Preview9Image6,
            ],
            // Preview 10
            [
                this.resources.items.Preview10Image1,
                this.resources.items.Preview10Image2,
                this.resources.items.Preview10Image3,
                this.resources.items.Preview10Image4,
                this.resources.items.Preview10Image5,
                this.resources.items.Preview10Image6,
            ],
            // Preview 11
            [
                this.resources.items.Preview11Image1,
                this.resources.items.Preview11Image2,
                this.resources.items.Preview11Image3,
                this.resources.items.Preview11Image4,
                this.resources.items.Preview11Image5,
                this.resources.items.Preview11Image6,
            ],
            // Preview 12
            [
                this.resources.items.Preview12Image1,
                this.resources.items.Preview12Image2,
                this.resources.items.Preview12Image3,
                this.resources.items.Preview12Image4,
                this.resources.items.Preview12Image5,
                this.resources.items.Preview12Image6,
            ],
        ];

        this.backImages = [
            this.resources.items.BackImage1,
            this.resources.items.BackImage2,
            this.resources.items.BackImage3,
            this.resources.items.BackImage4,
            this.resources.items.BackImage5,
            this.resources.items.BackImage6,
            this.resources.items.BackImage7,
            this.resources.items.BackImage8,
            this.resources.items.BackImage9,
            this.resources.items.BackImage10,
            this.resources.items.BackImage11,
            this.resources.items.BackImage12,
        ];

        this.images.forEach((arrayElement) => {
            arrayElement.forEach((image) => {
                image.magFilter = THREE.LinearFilter;
                image.minFilter = THREE.LinearFilter;
            });
        });

        this.currentImageFrame = 0;

        this.group = new THREE.Group();
        this.product = new THREE.Group();
        this.setCompareObjects();
        this.setModel();
    }

    setCompareObjects() {
        const banana = new Banana();
        const penguin = new Penguin();
        const elephant = new Elephant();
        const pyramid = new Pyramid();
        const eiffel = new Eiffel();
        const everest = new Everest();
        const moon = new Moon();

        this.compareObjects = [
            banana,
            penguin,
            elephant,
            pyramid,
            eiffel,
            everest,
            moon,
        ];

        this.compareObjects.forEach((object) => {
            this.group.add(object.model);
        });
    }

    outlineStand(show) {
        if (show) {
            this.experience.renderer.addOutlinePass(true);
        } else {
            this.experience.renderer.addOutlinePass(false);
        }
    }

    openStand(open) {
        if (open) {
            // Open Stand.
            new TWEEN.Tween(this.standMove.rotation)
                .to(
                    {
                        z: STAND_OPEN_Z_ROTATION,
                    },
                    OPEN_STAND_DURATION
                )
                .easing(TWEEN.Easing.Quartic.In)
                .start();

            // Upright group.
            new TWEEN.Tween(this.product.rotation)
                .to(
                    {
                        x: UPRIGHT_X_ROTATION,
                    },
                    OPEN_STAND_DURATION
                )
                .start();
        } else {
            // Close Stand.
            new TWEEN.Tween(this.standMove.rotation)
                .to(
                    {
                        z: STAND_CLOSE_Z_ROTATION,
                    },
                    CLOSE_STAND_DURATION
                )
                .start();

            // Flat group.
            new TWEEN.Tween(this.product.rotation)
                .to(
                    {
                        x: FLAT_X_ROTATION,
                    },
                    FLAT_DURATION
                )
                .easing(TWEEN.Easing.Quartic.In)
                .start();
        }
    }

    setScale(x) {
        const scale = 1 / x;
        this.tweenScale(scale, 1000);
    }

    tweenScale(targetScale, duration) {
        if (this.lastTween) this.lastTween.stop();
        this.lastTween = new TWEEN.Tween(this.group.scale)
            .to(
                {
                    x: targetScale,
                    y: targetScale,
                    z: targetScale,
                },
                duration
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();
    }

    setModel() {
        // Cast shadows.
        this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.castShadow = true;
                child.receiveShadow = true;

                if (child.name == "Cube001_1") {
                    this.backMesh = child;
                }
            }
        });

        // Image Mesh
        this.wwImage = new THREE.PlaneGeometry(1.587, 1.587, 1, 1);
        this.imageMaterial = new THREE.MeshBasicMaterial({
            map: this.images[this.imageIndex][this.currentImageFrame],
        });
        this.wwImageMesh = new THREE.Mesh(this.wwImage, this.imageMaterial);
        this.wwImageMesh.position.y = 0.8;
        this.wwImageMesh.position.z = 0.026;
        this.product.add(this.wwImageMesh);

        // Stand Material
        const standMaterial = new THREE.MeshStandardMaterial({
            roughness: 0,
            metalness: 1,
            color: 0x000000,
            emissive: 0x000000,
        });

        // Stand Mesh
        this.standMesh = this.model.children.find(
            (child) => child.name === "stand"
        );
        // this.standMesh.material = standMaterial;

        // Stand Move Mesh
        this.standMove = this.model.children.find(
            (child) => child.name === "standMove"
        );
        this.standMove.rotation.z = Math.PI * 0.5;
        this.standMove.castShadow = true;

        this.experience.renderer.addOutlineObject(this.standMesh);
        this.experience.renderer.addOutlineObject(this.standMove);

        // Glass Mesh
        this.glassMesh = this.model.children.find(
            (child) => child.name === "glass"
        );

        // Glass Material
        const glassMaterial = new THREE.MeshPhysicalMaterial({
            roughness: 0.05,
            transmission: 1,
            thickness: 0,
        });
        this.glassMesh.material = glassMaterial;
        // this.glassMesh.material.transparent = true;
        // this.glassMesh.material.opacity = 0.35;

        // Group Object
        this.product.add(this.model);
        this.group.add(this.product);
        this.group.position.set(0, 0, 0);
        this.group.scale.set(1, 1, 1);
        this.product.rotation.x = UPRIGHT_X_ROTATION;

        this.scene.add(this.group);
    }

    updateImageController(index) {
        if (index >= 0 && index < this.images.length) {
            if (index != this.imageIndex) {
                // Update front image.
                this.imageIndex = index;
                this.wwImageMesh.material.map =
                    this.images[this.imageIndex][this.currentImageFrame];

                // Update back image.
                this.updateBackImage(index);
            }
        }
    }

    updateBackImage(index) {
        if (index >= 0 && index < this.images.length) {
            this.backMesh.material.map.image = this.backImages[index].image;
            this.backMesh.material.map.needsUpdate = true;
        }
    }

    updateImage(angle) {
        const newFrame = Math.abs(Math.floor(angle / 8) % 6);
        if (newFrame !== this.currentImageFrame) {
            this.currentImageFrame = newFrame;
            this.wwImageMesh.material.map =
                this.images[this.imageIndex][this.currentImageFrame];
        }
    }

    update() {
        // Update image based on viewing angle.
        const xDist = this.group.position.x - this.camera.instance.position.x;
        const zDist = this.group.position.z - this.camera.instance.position.z;
        const viewingAngle = (Math.atan2(zDist, xDist) * 180) / Math.PI;
        this.updateImage(viewingAngle);

        // for each object in this.compareObjects call update function if exists
        this.compareObjects.forEach((object) => {
            if (object.update) {
                object.update();
            }
        });
    }
}
