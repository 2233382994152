import Experience, { SCENE_HOME } from "../Experience";
import Debug from "./Debug";

const autoScrollSpeed = 0.3;

export default class ScrollDetector {
    constructor() {
        this.experience = new Experience();
        this.debug = new Debug();
        this.time = this.experience.time;
        this.scrollProgress = 0;
        this.trueProgress = this.scrollProgress;

        // for smoother mobile scrolling
        this.momentum = 0;

        this.scrollLocked = false;
        this.autoScroll = false;

        this.setupEventListener();

        this.autoScrollStartTimeout = window.setTimeout(() => {
            this.autoScroll = true;
        }, 5000);
    }

    lockScroll(lock) {
        this.scrollLocked = lock;
    }

    handleScroll(deltaY) {
        if (!this.scrollLocked) {
            var amountChange = deltaY * 0.0001;

            this.trueProgress = Math.min(
                Math.max(0, this.trueProgress + amountChange),
                1
            );

            if (this.trueProgress < 0.97 || this.autoScroll) {
                this.scrollProgress = this.trueProgress;
            } else {
                this.scrollProgress = 1;
            }
        }
    }

    touchDetected() {
        this.autoScroll = false;
        window.clearTimeout(this.autoScrollStartTimeout);
    }

    setupEventListener() {
        window.addEventListener(
            "wheel",
            (event) => {
                this.touchDetected();
                if (this.experience.activeScene == SCENE_HOME) {
                    this.handleScroll(event.deltaY);
                }
            },
            { passive: false }
        );

        this.ts = 0;
        window.addEventListener(
            "touchstart",
            (event) => {
                this.touchDetected();
                if (this.experience.activeScene == SCENE_HOME) {
                    this.momentum = 0;
                    this.ts = event.touches[0].clientY;
                }
            },
            { passive: false }
        );

        window.addEventListener(
            "touchmove",
            (event) => {
                this.touchDetected();
                if (this.experience.activeScene == SCENE_HOME) {
                    var te = event.changedTouches[0].clientY;
                    var scrollValue = (this.ts - te) * 8;
                    this.handleScroll(scrollValue);
                    this.ts = te;
                    this.momentum = scrollValue * 0.55;
                }
            },
            { passive: false }
        );
    }

    update() {
        if (this.momentum != 0) {
            this.handleScroll(this.momentum);
            this.momentum = this.momentum * 0.55 ** (this.time.delta * 0.25);
            if (this.momentum < 0.1) {
                this.momentum = 0;
            }
        }

        if (this.autoScroll) {
            if (this.trueProgress == 1) {
                this.autoScroll = false;
            } else {
                this.handleScroll(this.time.delta * autoScrollSpeed);
            }
        }
    }
}
